<template>
     <Slider
          v-if="type == 'slider'"
          :data="games"
          title="New games"
          :onNextSlide="onSeeMore"
          :pagination="pagination"
          active="new"
     >
          <template v-slot:icon>
               <span
                    class="text-2xl"
                    :class="'icon-' + active"
               ></span>
          </template>
     </Slider>
     <Paginate
          v-else-if="type == 'paginate'"
          :data="games"
          title="New games"
          :onSeeMore="onSeeMore"
          :pagination="pagination"
          active="new"
     >
          <template v-slot:icon>
               <span
                    class="text-2xl"
                    :class="'icon-' + active"
               ></span>
          </template>
     </Paginate>
</template>
<script>
import { computed, reactive, watch } from "vue";
import { gamesStore } from "store/games";
import Slider from "components/games/slider/index.vue";
import Paginate from "components/games/paginate/index.vue";

export default {
     components: {
          Slider,
          Paginate
     },
     props: ["type"],
     setup() {
          const useGamesStore = gamesStore();

          const games = reactive({
               data: null
          });

          const pagination = reactive({
               current: null,
               last: null,
               next: null
          });

          const gamesData = computed(() => {
               if (useGamesStore.allGames["new"]) {
                    games.data = useGamesStore.allGames["new"]["data"];
                    pagination["current"] =
                         useGamesStore.allGames["new"]["current_page"];
                    pagination["last"] =
                         useGamesStore.allGames["new"]["last_page"];
                    const temp = useGamesStore.allGames["new"]["next_page_url"];
                    pagination["next"] =
                         temp != null ? temp.slice(-1) : pagination["last"];
               }
               return useGamesStore.allGames["new"] || null;
          });

          const params = reactive({});

          const gameFilter = computed(() => {
               const obj = Object.keys(useGamesStore.gameFilter);
               for (let index = 0; index < obj.length; index++) {
                    params[obj[index]] = useGamesStore.gameFilter[obj[index]];
               }

               return useGamesStore.gameFilter;
          });

          const onSeeMore = async () => {
               const data = {
                    game_name: "",
                    game_promo: 0,
                    game_type: [],
                    provider_id: params.provider_id,
                    page: pagination["next"],
                    itemsPerPage: 21,
                    sortType: params.sortType,
                    game_new: 1,
                    recommended: 1
               };

               await useGamesStore.getGames(data, "new", false, true);
          };

          watch(gamesData, (newVal) => {
               if (newVal["new"]) {
                    games.data = newVal["new"]["data"];
                    pagination["current"] = newVal["new"]["current_page"];
                    pagination["last"] = newVal["new"]["last_page"];
                    const temp = newVal["new"]["next_page_url"];
                    pagination["next"] =
                         temp != null ? temp.slice(-1) : pagination["last"];
               }
          });

          watch(gameFilter, () => {});

          return {
               games,
               onSeeMore,
               pagination
          };
     }
};
</script>

<template>
     <div
          class="px-5 md:px-12 lg:px-20 xl:px-32 py-8 md:py-24 lg:py-28 xl:py-32"
     >
          <h1
               class="text-white font-helvetica tracking-wide text-center text-6xl xs:text-6xl sm:text-7xl md:text-7xl xl:text-8xl leading-5xl xs:leading-5xl sm:leading-6xl md:leading-6xl xl:leading-7.5xl"
          >
               {{ promoDetail["title"] }}, GET COINS
          </h1>
          <div
               class="mt-8 md:mt-14 xl:mt-18 px-2 sm:px-6 md:px-10 lg:px-14 xl:px-32"
          >
               <p
                    class="text-white font-helveticaLight tracking-wide text-center md:text-start text-xl sm:text-2xl md:text-3xl lg:text-2xl xl:text-3xl leading-2xl sm:leading-3xl lg:leading-3xl xl:leading-4xl font-bold"
               >
                    Share the joy of Moozi with your friends! You’ll get free
                    coins up to
                    <span
                         class="text-secondary-3 font-helveticaLight tracking-wide text-center md:text-start text-xl sm:text-2xl md:text-3xl lg:text-2xl xl:text-3xl leading-2xl sm:leading-3xl lg:leading-3xl xl:leading-4xl"
                    >
                         1.1 millions GC
                    </span>
                    and
                    <span
                         class="text-secondary-3 font-helveticaLight tracking-wide text-center md:text-start text-xl sm:text-2xl md:text-3xl lg:text-2xl xl:text-3xl leading-2xl sm:leading-3xl lg:leading-3xl xl:leading-4xl"
                         >110 free SC</span
                    >!
               </p>
               <div v-if="isUser && showShare">
                    <div class="py-6 sm:py-8 md:py-10 lg:py-12 xl:py-14">
                         <label
                              for="email"
                              class="block mb-1 sm:mb-2 text-sm font-normal text-white"
                              >Email will be send to:</label
                         >
                         <div
                              class="flex flex-col lg:flex-row gap-2 sm:gap-3 lg:gap-4 xl:gap-5"
                         >
                              <div class="flex-1 font-roboto">
                                   <input
                                        v-model="form.email"
                                        type="email"
                                        name="email"
                                        id="email"
                                        class="bg-white border border-shade-10 text-shade-2 text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl rounded-full focus:ring-background-2 focus:border-background-2 block w-full pl-3 sm:pl-4 md:pl-6 lg:pl-8 xl:pl-10 pr-3 py-2 sm:py-3 font-normal"
                                        :placeholder="
                                             form.errors?.errors?.email
                                                  ? form.errors?.errors?.email
                                                  : 'Your Friends’s emails'
                                        "
                                        autocomplete="email"
                                        @change="clearFormError('email')"
                                        :class="
                                             form.errors?.errors?.email != null
                                                  ? '!border-primal-red focus:ring-primal-red focus:border-primal-red'
                                                  : 'border-shade-10 focus:ring-background-2 focus:border-background-2'
                                        "
                                   />
                                   <has-error-form
                                        v-model:form="form"
                                        :field="'profile_form'"
                                   ></has-error-form>
                              </div>
                              <button
                                   @click="shareLink('email')"
                                   type="button"
                                   class="login-btn font-roboto text-black bg-secondary-3 rounded-full font-semibold text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl px-2 sm:px-4 lg:px-6 xl:px-8 pt-3 lg:pt-2 pb-3 lg:pb-1 text-center tracking-wide w-full lg:w-max"
                              >
                                   Send Email
                              </button>
                         </div>
                    </div>
                    <div class="pb-6 sm:pb-8 md:pb-10 lg:pb-12 xl:pb-14">
                         <label
                              for="email"
                              class="block mb-1 sm:mb-2 text-sm font-normal text-white"
                              >Or share your unique URL:</label
                         >
                         <div class="flex gap-2 sm:gap-3 lg:gap-4 xl:gap-5">
                              <div class="flex-1 font-roboto relative">
                                   <input
                                        v-model="link"
                                        type="link"
                                        name="link"
                                        id="link"
                                        class="bg-shade-21 text-background-1 text-sm sm:text-base md:text-base lg:text-xl xl:text-2xl rounded-full block w-full pl-3 sm:pl-4 md:pl-6 lg:pl-8 xl:pl-10 pr-20 py-2 sm:py-3 font-normal border-shade-10 focus:ring-background-2 focus:border-background-2"
                                        autocomplete="link"
                                        :placeholder="link"
                                        readonly
                                   />
                                   <div
                                        class="absolute cursor-pointer flex items-center h-full right-3 sm:right-4 md:right-6 lg:right-8 xl:right-10"
                                        style="top: 0px"
                                   >
                                        <button
                                             @click="copyLink(link)"
                                             type="button"
                                             class="font-roboto text-secondary-3 rounded-full font-normal text-xs sm:text-sm md:text-sm lg:text-base xl:text-xl text-center tracking-wide"
                                        >
                                             Copy Link
                                        </button>
                                   </div>
                              </div>
                         </div>

                         <div
                              class="flex flex-wrap gap-2 sm:gap-4 md:gap-8 lg:gap-12 xl:gap-20 items-center w-full justify-center mt-4 md:mt-6 xl:mt-8"
                         >
                              <div
                                   v-for="(item, i) in referralSocial"
                                   :key="i"
                                   class="flex-1 md:flex-initial font-roboto flex flex-col gap-1 xl:gap-2 items-center justify-center cursor-pointer scale hover:scale-105 transition-transform duration-300"
                                   @click="shareLink(item.toLowerCase())"
                                   :class="
                                        ['messenger', 'sms'].includes(
                                             item.toLowerCase()
                                        )
                                             ? deviceType() == 'mobile'
                                                  ? ''
                                                  : 'hidden'
                                             : ''
                                   "
                              >
                                   <img
                                        class="w-8 sm:w-auto"
                                        :src="
                                             '/assets/img/promotion/referralsocial/' +
                                             item +
                                             '.png'
                                        "
                                        alt=""
                                   />
                                   <div>
                                        <div
                                             class="font-normal text-white text-xs sm:text-sm"
                                        >
                                             {{ item }}
                                        </div>
                                   </div>
                              </div>
                         </div>

                         <div
                              class="flex items-center w-full justify-center mt-6 sm:mt-8 md:mt-10 lg:mt-12 xl:mt-14"
                         >
                              <div
                                   @click="openStats"
                                   class="rounded-full font-roboto font-extrabold tracking-wider px-4 sm:px-8 md:px-12 lg:px-16 xl:px-20 py-3 bg-secondary-3 text-primary-1 text-sm sm:text-lg md:text-xl lg:text-lg xl:text-xl cursor-pointer w-full lg:w-max text-center"
                              >
                                   My Stats
                              </div>
                         </div>
                    </div>
               </div>
               <div
                    v-else-if="isUser && !showShare"
                    class="flex items-center w-full justify-center mt-6 sm:mt-8 md:mt-10 lg:mt-12 xl:mt-14"
               >
                    <div
                         @click="open_kyc_referral"
                         class="rounded-full font-roboto font-extrabold tracking-wider px-6 sm:px-6 md:px-8 lg:px-10 xl:px-12 py-3 bg-secondary-3 text-primary-1 text-sm sm:text-lg md:text-xl lg:text-lg xl:text-xl cursor-pointer"
                    >
                         {{ textBtn }}
                    </div>
               </div>
               <div
                    v-else
                    class="flex items-center w-full justify-center mt-6 sm:mt-8 md:mt-10 lg:mt-12 xl:mt-14"
               >
                    <div
                         @click="goToLogin"
                         class="rounded-full font-roboto font-extrabold tracking-wider px-6 sm:px-6 md:px-8 lg:px-10 xl:px-12 py-3 bg-secondary-3 text-primary-1 text-sm sm:text-lg md:text-xl lg:text-lg xl:text-xl cursor-pointer"
                    >
                         Log In To Share
                    </div>
               </div>
               <div class="mt-6 sm:mt-8 md:mt-10 lg:mt-12 xl:mt-14">
                    <h1
                         class="text-white font-helvetica tracking-wide text-4xl xs:text-4xl sm:text-5xl md:text-5xl xl:text-6xl leading-3xl xs:leading-3xl sm:leading-4xl md:leading-4xl xl:leading-5xl mb-1"
                    >
                         RULES:
                    </h1>
                    <p
                         class="font-normal text-white text-sm md:text-base xl:text-lg"
                    >
                         <span
                              >In order to redeem free GC and SC referral bonus
                              packages, please ensure you follow these steps
                              listed below:</span
                         >
                         <br /><br />
                         <span>
                              <span class="pl-3 md:pl-5"
                                   >1. First, complete your own KYC forms.</span
                              ><br />
                              <span class="pl-3 md:pl-5"
                                   >2. Refer a friend, then redeem the bonus
                                   packages.</span
                              >
                         </span>
                         <br /><br />
                         <span>
                              If you have NOT completed your KYC forms, please
                              ensure you have done this before referring a
                              friend to be eligible for the promotion. Bonus GC
                              and SC are ONLY awarded once you and your referred
                              friends have completed all KYC forms.
                         </span>
                         <br /><br />
                         <span>
                              Please notice, ONLY new customers are valid for
                              the referrals.
                         </span>
                         <br /><br />
                         <span>
                              By adding this account, I agree and accept the
                              <router-link
                                   :to="{ path: '/terms-of-use' }"
                                   target="_blank"
                              >
                                   <span
                                        class="text-secondary-3 underline cursor-pointer"
                                        >Terms of Service</span
                                   > </router-link
                              >,
                              <router-link
                                   :to="{ path: '/sc-coins' }"
                                   target="_blank"
                              >
                                   <span
                                        class="text-secondary-3 underline cursor-pointer"
                                        >SC Promotional Rules</span
                                   ></router-link
                              >
                              , and
                              <router-link
                                   :to="{ path: '/privacy' }"
                                   target="_blank"
                              >
                                   <span
                                        class="text-secondary-3 underline cursor-pointer"
                                        >Privacy Policy</span
                                   >
                              </router-link>
                              .
                         </span>
                    </p>
               </div>

               <div class="mt-6 sm:mt-8 md:mt-10 lg:mt-12 xl:mt-14">
                    <h1
                         class="text-white font-helvetica tracking-wide text-4xl xs:text-4xl sm:text-5xl md:text-5xl xl:text-6xl leading-3xl xs:leading-3xl sm:leading-4xl md:leading-4xl xl:leading-5xl mb-1"
                    >
                         Benefits:
                    </h1>
                    <ul class="ml-8 list-disc text-white">
                         <li class="mb-2 text-sm md:text-base xl:text-lg">
                              1st refer a friend bonus 50000GC 5SC
                         </li>
                         <li class="mb-2 text-sm md:text-base xl:text-lg">
                              2nd 50000GC 5SC
                         </li>
                         <li class="mb-2 text-sm md:text-base xl:text-lg">
                              3rd 50000GC 5SC
                         </li>
                         <li class="mb-2 text-sm md:text-base xl:text-lg">
                              4th 100000GC 10SC
                         </li>
                         <li class="mb-2 text-sm md:text-base xl:text-lg">
                              5th 100000GC 10SC
                         </li>
                         <li class="mb-2 text-sm md:text-base xl:text-lg">
                              6th 100000GC 10SC
                         </li>
                         <li class="mb-2 text-sm md:text-base xl:text-lg">
                              7th 150000GC 15SC
                         </li>
                         <li class="mb-2 text-sm md:text-base xl:text-lg">
                              8th 150000GC 15SC
                         </li>
                         <li class="mb-2 text-sm md:text-base xl:text-lg">
                              9th 150000GC 15SC
                         </li>
                         <li class="mb-2 text-sm md:text-base xl:text-lg">
                              10th 200000GC 20SC
                         </li>
                    </ul>
               </div>
          </div>
          <div
               class="w-full flex justify-center items-center mt-8 md:mt-14 xl:mt-28"
          >
               <div
                    @click="back"
                    class="rounded-full font-roboto font-extrabold tracking-wider px-10 py-3 bg-secondary-3 text-primary-1 text-sm sm:text-lg md:text-xl lg:text-lg xl:text-xl cursor-pointer"
               >
                    Back to Promotions
               </div>
          </div>
          <StatsModal v-if="isUser"></StatsModal>
     </div>
</template>

<script>
import { computed, onMounted, ref, reactive, watch } from "vue";
import { useRouter } from "vue-router";
import _ from "underscore";
import Form from "vform";
import HasErrorForm from "components/haserror/index.vue";
import { authStore } from "@/store/auth";
import { useToaster } from "composables/use-toaster.js";
import eventBus from "plugins/event.js";
import StatsModal from "./statsModal.vue";
import device from "middleware/device.js";
import { baseUrl } from "config/services.js";
import { useKYC } from "composables/use-kyc.js";

export default {
     props: ["promoDetail", "loading"],
     components: {
          HasErrorForm,
          StatsModal
     },
     setup(props, { emit }) {
          const { open_kyc_referral } = useKYC();
          const router = useRouter();
          const useAuthStore = authStore();
          const { successToast, errorToast } = useToaster();
          const { deviceType } = device();

          const referralSocial = ref(["Messenger", "Facebook", "X", "SMS"]);

          const link = ref("");

          const showShare = ref(null);
          const textBtn = ref("");

          const form = reactive(
               new Form({
                    email: ""
               })
          );

          const isUser = computed(() => {
               return useAuthStore.user;
          });

          const referral_code = computed(() => {
               return useAuthStore.referral_code;
          });

          const error_msg = computed(() => {
               return useAuthStore.error_msg;
          });

          const onSubmit = async () => {
               emit("update:loading", true);

               try {
                    // await useUtilitiesStore.createTicket(form);
                    form.reset();
                    emit("update:loading", false);
                    successToast("Successfully Sent", "top-right");
               } catch {
                    emit("update:loading", false);
                    errorToast("Failed to send email", "top-right");
               }
          };

          const clearFormError = (key) => {
               useAuthStore.clearCurrentFormError(key);
          };

          const back = () => {
               router.push({
                    path: "/promotion"
               });
          };

          const copyLink = (code) => {
               const codeEl = document.createElement("textarea");
               codeEl.value = code;
               document.body.appendChild(codeEl);
               codeEl.select();
               document.execCommand("copy");
               document.body.removeChild(codeEl);
               successToast("Link is copied successfully!", "top-right");
               event.preventDefault();
          };

          const openStats = () => {
               eventBus.emit("open:stats");
          };

          const goToLogin = () => {
               router.push({ path: "/signin" });
          };

          const shareLink = (socials) => {
               // ['Messenger', 'Meta', 'X', 'SMS']
               const url = link.value;
               const text = "Share the fun at Moozi and get FREE SCs coins\n\n";

               switch (socials) {
                    case "messenger":
                         var messengerUrl =
                              "fb-messenger://share/?text=" +
                              encodeURIComponent(text) +
                              "&link=" +
                              encodeURIComponent(url);
                         window.open(messengerUrl, "_blank");
                         break;
                    case "facebook":
                         var fbShareUrl =
                              "https://www.facebook.com/sharer/sharer.php?u=" +
                              encodeURIComponent(url) +
                              "&quote=" +
                              encodeURIComponent(text);
                         window.open(fbShareUrl, "_blank");
                         break;
                    case "x":
                         var twitterShareUrl =
                              "https://twitter.com/intent/tweet?text=" +
                              encodeURIComponent(text) +
                              "&url=" +
                              encodeURIComponent(url);
                         window.open(twitterShareUrl, "_blank");
                         break;
                    case "sms":
                         var smsUrl =
                              "sms:" +
                              "" +
                              "?body=" +
                              encodeURIComponent(text + " " + url);
                         window.open(smsUrl, "_blank");
                         break;
                    case "email":
                         if (form.email == "") {
                              form.errors.errors.email = "Empty email";
                              return;
                         }

                         const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                         if (!pattern.test(form.email)) {
                              form.errors.errors.email = "Invalid email";
                              return;
                         }

                         var gmailUrl =
                              "mailto:" +
                              form.email +
                              "?subject=" +
                              encodeURIComponent("Moozi") +
                              "&body=" +
                              encodeURIComponent(text + " " + url);
                         window.open(gmailUrl, "_blank");
                         break;
                    default:
                         break;
               }
          };

          watch(error_msg, (newVal) => {
               if (newVal) {
                    useAuthStore.updateProfileFormValue("errors", newVal);
               }
          });

          watch(referral_code, (newVal) => {
               if (newVal) {
                    link.value = "https://moozi.com/signin?ref=" + newVal;
               }
          });

          // watch(link, (newVal) => {
          //     if (newVal) {
          //         link.value = newVal;
          //     }
          // });

          onMounted(async () => {
               const kyc = open_kyc_referral();
               if (kyc.status == true) {
                    showShare.value = false;
                    textBtn.value = kyc.type;
                    return;
               }

               showShare.value = true;
               textBtn.value = kyc.type;

               const filtered = _.find(isUser.value?.meta, function (params) {
                    return params["meta_key"] == "referral_code";
               });

               if (filtered) {
                    const parseFilter = JSON.parse(filtered.meta_value);
                    link.value = "https://moozi.com/signin?ref=" + parseFilter;
               } else {
                    await useAuthStore.getReferralCode();
               }
          });

          return {
               back,
               form,
               onSubmit,
               clearFormError,
               copyLink,
               link,
               referralSocial,
               openStats,
               isUser,
               goToLogin,
               shareLink,
               deviceType,
               baseUrl,
               showShare,
               textBtn,
               open_kyc_referral
          };
     }
};
</script>

<template>
     <InertiaHead title="Gold Coin Shop"></InertiaHead>
     <div class="w-full h-full relative mt-0">
          <div class="w-full bg-transparent relative">
               <div
                    v-if="isSelfExclusion == null"
                    class="w-full h-full flex flex-col justify-center items-center px-5 xs:px-8 sm:px-10 md:px-24 lg:px-28 xl:px-32 pb-36 sm:pb-44 md:pb-20 lg:pb-28 xl:pb-32 pt-16 sm:pt-20 lg:pt-28 xl:pt-32"
               >
                    <h1
                         class="block text-white font-helvetica tracking-wide text-center text-5xl xs:text-5xl sm:text-5xl md:text-6xl xl:text-8xl leading-5xl xs:leading-5xl sm:leading-6xl md:leading-6xl xl:leading-7.5xl"
                    >
                         BUY A GOLD COIN PACKAGE!
                    </h1>
                    <p
                         class="text-white font-helveticaLight tracking-wide mt-5 md:mt-8 mb-4 md:mb-8 text-center font-normal text-sm sm:text-lg md:text-xl lg:text-lg xl:text-xl"
                    >
                         Moozi have a diverse selection of deals to every
                         gamer's preference. <br />
                         Special offers end soon, buy your packages ASAP.
                    </p>
                    <div
                         class="bg-moozi-2 w-full lg:w-8/12 rounded-xl mt-10 mb-5"
                    >
                         <div
                              class="flex flex-row border-b border-solid border-primary-5 w-full"
                         >
                              <div
                                   class="slider-wrapper w-full lg:w-8/12 border-r border-solid border-primary-5 p-5 lg:p-8"
                              >
                                   <p
                                        class="text-white font-helveticaLight tracking-wide mb-4 text-start font-bold text-sm sm:text-base md:text-lg lg:text-base xl:text-lg"
                                   >
                                        Select Amount
                                   </p>
                                   <div class="w-full">
                                        <Slider
                                             v-model="sliderVal"
                                             :format="
                                                  (v) =>
                                                       `$${money(Math.round(v))}`
                                             "
                                             size="20px"
                                             :min="5"
                                             :max="5000"
                                             showTooltip="focus"
                                        />
                                        <div
                                             class="flex flex-row justify-between item-center mt-2"
                                        >
                                             <p
                                                  class="text-white font-helveticaLight tracking-wide text-start font-bold text-sm md:text-base lg:text-sm xl:text-base"
                                             >
                                                  $5
                                             </p>
                                             <p
                                                  class="text-white font-helveticaLight tracking-wide text-start font-bold text-sm md:text-base lg:text-sm xl:text-base"
                                             >
                                                  $5,000
                                             </p>
                                        </div>
                                   </div>
                                   <div
                                        class="flex flex-row justify-between item-center relative z-10 mt-3"
                                   >
                                        <span
                                             @click="(e) => minusAmount(e)"
                                             @mousedown="
                                                  (e) => onHold(e, 'minus')
                                             "
                                             @mouseup="stopIncrement()"
                                             @mouseleave="stopIncrement()"
                                             @touchstart.prevent="
                                                  (e) => onHold(e, 'minus')
                                             "
                                             @touchend.prevent="stopIncrement()"
                                             @touchcancel.prevent="
                                                  stopIncrement()
                                             "
                                             class="icon-minus-box text-2xl"
                                             :class="
                                                  sliderVal > 5
                                                       ? 'cursor-pointer'
                                                       : 'opacity-50 cursor-not-allowed'
                                             "
                                        ></span>
                                        <span
                                             @click="(e) => addAmount(e)"
                                             @mousedown="
                                                  (e) => onHold(e, 'add')
                                             "
                                             @mouseup="stopIncrement()"
                                             @mouseleave="stopIncrement()"
                                             @touchstart.prevent="
                                                  (e) => onHold(e, 'add')
                                             "
                                             @touchend.prevent="stopIncrement()"
                                             @touchcancel.prevent="
                                                  stopIncrement()
                                             "
                                             class="icon-add-box text-2xl"
                                             :class="
                                                  sliderVal < 5000
                                                       ? 'cursor-pointer'
                                                       : 'opacity-50 cursor-not-allowed'
                                             "
                                        ></span>
                                   </div>
                                   <!--   -->
                                   <!--  -->

                                   <!--
                                        :disabled="false" -->
                              </div>
                              <div
                                   class="lg:w-4/12 hidden lg:flex flex-col items-center justify-center px-5 py-8"
                              >
                                   <p
                                        class="text-white font-helveticaLight tracking-wide mb-4 text-center font-bold text-sm sm:text-base md:text-lg lg:text-base xl:text-lg"
                                   >
                                        Total Coins
                                   </p>
                                   <div class="flex items-center gap-3 mb-1">
                                        <img
                                             class="cursor-pointer -ml-2 w-8 lg:w-8"
                                             :src="
                                                  baseUrl.img +
                                                  '/assets/img/header/GC.svg'
                                             "
                                             alt=""
                                        />
                                        <span
                                             class="text-secondary-7 font-roboto font-bold tracking-wide text-center text-sm sm:text-lg md:text-xl lg:text-lg xl:text-2xl 2xl:text-3xl"
                                        >
                                             {{ money(sliderVal * 10000) }}
                                        </span>
                                   </div>
                                   <div class="flex items-center gap-3">
                                        <img
                                             class="cursor-pointer -ml-2 w-8 lg:w-7"
                                             :src="
                                                  baseUrl.img +
                                                  '/assets/img/header/SC.svg'
                                             "
                                             alt=""
                                        />
                                        <span
                                             class="text-tertiary-3 font-roboto font-bold tracking-wide text-center text-xxs sm:text-xs md:text-base lg:text-medium xl:text-lg 2xl:text-xl"
                                        >
                                             + FREE {{ money(sliderVal * 1) }}
                                        </span>
                                   </div>
                              </div>
                         </div>
                         <div class="flex flex-row w-full p-0 lg:p-8">
                              <div
                                   class="w-2/4 flex flex-col items-center justify-between lg:hidden border-r border-solid border-primary-5 p-5"
                              >
                                   <p
                                        class="text-white font-helveticaLight tracking-wide mb-4 text-center font-bold text-sm sm:text-base md:text-lg lg:text-base xl:text-lg"
                                   >
                                        Total Coins
                                   </p>
                                   <div
                                        class="flex flex-col items-center justify-center"
                                   >
                                        <div
                                             class="flex items-center gap-3 mb-1"
                                        >
                                             <img
                                                  class="cursor-pointer -ml-2 w-6 md:w-8"
                                                  :src="
                                                       baseUrl.img +
                                                       '/assets/img/header/GC.svg'
                                                  "
                                                  alt=""
                                             />
                                             <span
                                                  class="text-secondary-7 font-roboto font-bold tracking-wide text-center text-xl sm:text-2xl md:text-3xl lg:text-lg xl:text-2xl 2xl:text-3xl"
                                             >
                                                  {{ money(sliderVal * 10000) }}
                                             </span>
                                        </div>
                                        <div class="flex items-center gap-3">
                                             <img
                                                  class="cursor-pointer -ml-2 w-5 md:w-7"
                                                  :src="
                                                       baseUrl.img +
                                                       '/assets/img/header/SC.svg'
                                                  "
                                                  alt=""
                                             />
                                             <span
                                                  class="text-tertiary-3 font-roboto font-bold tracking-wide text-center text-lg sm:text-xl md:text-2xl lg:text-lg xl:text-2xl 2xl:text-3xl"
                                             >
                                                  + FREE
                                                  {{ money(sliderVal * 1) }}
                                             </span>
                                        </div>
                                   </div>
                                   <div></div>
                              </div>
                              <div
                                   class="w-2/4 lg:w-full flex flex-col lg:flex-row justify-between items-center gap-5 lg:gap-0 p-5 lg:p-0"
                              >
                                   <div
                                        class="hidden lg:flex flex-row gap-8 items-center"
                                   >
                                        <p
                                             class="text-white font-helveticaLight tracking-wide text-start lg:text-center md:text-start font-bold text-sm sm:text-base md:text-lg lg:text-base xl:text-lg"
                                        >
                                             Total Price
                                        </p>
                                        <p
                                             class="text-secondary-7 font-roboto font-bold tracking-wide text-center text-sm sm:text-lg md:text-xl lg:text-lg xl:text-2xl 2xl:text-3xl"
                                        >
                                             ${{ money(sliderVal) }}
                                        </p>
                                   </div>
                                   <p
                                        class="block lg:hidden text-white font-helveticaLight tracking-wide text-center font-bold text-sm sm:text-base md:text-lg lg:text-base xl:text-lg"
                                   >
                                        Total Price
                                   </p>
                                   <p
                                        class="block lg:hidden text-secondary-7 font-roboto font-bold tracking-wide text-center text-xl sm:text-2xl md:text-3xl lg:text-lg xl:text-2xl 2xl:text-3xl"
                                   >
                                        ${{ money(sliderVal) }}
                                   </p>
                                   <div
                                        @click="
                                             proceedCheckOutCustom(
                                                  sliderVal * 10000,
                                                  sliderVal * 1,
                                                  sliderVal
                                             )
                                        "
                                        class="h-10 sm:h-11 flex flex-row items-center gap-1 rounded-full transition-transform duration-300 font-roboto font-bold tracking-wider px-5 lg:px-10 bg-secondary-3 text-primary-1 text-sm sm:text-base md:text-lg lg:text-base xl:text-lg !cursor-pointer"
                                   >
                                        <span
                                             class="cursor-pointer font-roboto text-sm lg:text-lg font-bold"
                                        >
                                             Buy Now
                                        </span>
                                   </div>
                              </div>
                         </div>
                    </div>
                    <div class="mt-5 md:mt-7 lg:mt-12 xl:mt-20 w-full">
                         <div
                              class="hidden lg:flex flex-col justify-center items-center gap-14 w-full"
                         >
                              <div
                                   v-for="(item, key) in coins?.type"
                                   :key="key"
                                   class="flex flex-col justify-center items-center gap-5 w-full"
                              >
                                   <img
                                        :src="
                                             '/assets/img/shop/' +
                                             item.ribbon +
                                             '.png'
                                        "
                                        alt=""
                                        class="w-56 h-auto"
                                        v-if="goldPackage[item.value]"
                                   />
                                   <div
                                        v-for="(coin, kCoin) in goldPackage[
                                             item.value
                                        ]"
                                        :key="kCoin"
                                        :class="
                                             item.value == 1
                                                  ? 'bg-background-4'
                                                  : item.value == 2
                                                    ? 'bg-primary-4'
                                                    : item.value == 3
                                                      ? 'bg-primary-1'
                                                      : ''
                                        "
                                        class="lg:pt-3 2xl:pt-5 lg:pb-2 2xl:pb-3 lg:px-4 xl:px-8 2xl:px-10 flex flex-row justify-between w-full rounded-xl items-center scale-100 hover:scale-105 transition-transform duration-500 cursor-pointer"
                                        @click="
                                             proceedCheckOutPackage(
                                                  coin['id'],
                                                  item.label,
                                                  getSum(
                                                       coin.gold_coins,
                                                       coin.gold_coins_free
                                                  ),
                                                  getSum(
                                                       coin.silver_coins,
                                                       coin.silver_coins_free
                                                  ),
                                                  getPercentage(
                                                       coin.discount,
                                                       coin.net_amount
                                                  )
                                             )
                                        "
                                   >
                                        <div class="flex flex-row items-center">
                                             <img
                                                  :src="
                                                       '/assets/img/shop/' +
                                                       item.label
                                                            .split(' ')[0]
                                                            .toLowerCase() +
                                                       '.png'
                                                  "
                                                  alt=""
                                                  class="lg:w-32 xl:w-40 2xl:w-48 h-auto"
                                             />
                                             <p
                                                  class="uppercase lg:px-10 xl:px-10 2xl:px-16 text-secondary-7 font-helvetica tracking-wide text-center text-6xl xs:text-6xl sm:text-7xl md:text-7xl lg:text-4xl xl:text-5xl 2xl:text-6xl leading-5xl xs:leading-5xl sm:leading-6xl md:leading-6xl xl:leading-5xl 2xl:leading-6xl"
                                             >
                                                  {{
                                                       money(
                                                            getSum(
                                                                 coin.gold_coins,
                                                                 coin.gold_coins_free
                                                            )
                                                       )
                                                  }}
                                                  GC
                                             </p>
                                             <p
                                                  class="text-tertiary-3 font-roboto font-bold tracking-wide text-start lg:text-center md:text-start text-sm sm:text-lg md:text-xl lg:text-xl xl:text-2xl 2xl:text-3xl"
                                             >
                                                  +FREE
                                                  {{
                                                       money(
                                                            getSum(
                                                                 coin.silver_coins,
                                                                 coin.silver_coins_free
                                                            )
                                                       )
                                                  }}
                                                  SC
                                             </p>
                                        </div>
                                        <div class="relative">
                                             <div
                                                  class="flex flex-row justify-start items-center cursor-pointer"
                                             >
                                                  <div
                                                       v-if="coin.discount != 0"
                                                       class="absolute -left-10"
                                                  >
                                                       <img
                                                            :src="
                                                                 baseUrl.img +
                                                                 '/assets/img/shop/badge.png'
                                                            "
                                                            alt=""
                                                            class="lg:w-18 xl:w-20 2xl:w-24 lg:h-18 xl:h-20 2xl:h-24"
                                                       />
                                                       <div
                                                            class="absolute top-0 w-full h-full flex justify-center items-center text-white font-roboto font-bold"
                                                       >
                                                            -{{
                                                                 coin.discount
                                                            }}%
                                                       </div>
                                                  </div>
                                                  <div
                                                       class="w-max bg-secondary-3 rounded-full py-2 lg:px-4 xl:px-8 2xl:px-10 font-helvetica tracking-wide text-start lg:text-center md:text-start text-sm sm:text-lg md:text-xl lg:text-lg xl:text-2xl 2xl:text-3xl"
                                                  >
                                                       <span
                                                            v-if="
                                                                 coin.discount !=
                                                                 0
                                                            "
                                                            :class="
                                                                 coin.discount !=
                                                                 0
                                                                      ? 'ml-4'
                                                                      : ''
                                                            "
                                                            class="text-secondary-5 line-through mr-2"
                                                            >${{
                                                                 money(
                                                                      coin.net_amount
                                                                 )
                                                            }}</span
                                                       >
                                                       <span
                                                            class="text-primary-1"
                                                            >${{
                                                                 money(
                                                                      getPercentage(
                                                                           coin.discount,
                                                                           coin.net_amount
                                                                      )
                                                                 )
                                                            }}</span
                                                       >
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div
                              class="flex lg:hidden flex-col justify-center items-center gap-14 w-full"
                         >
                              <div
                                   v-for="(item, key) in coins?.type"
                                   :key="key"
                                   class="flex flex-col justify-center items-center gap-5 w-full"
                              >
                                   <img
                                        :src="
                                             '/assets/img/shop/' +
                                             item.ribbon +
                                             '.png'
                                        "
                                        alt=""
                                        class="w-56 h-auto"
                                        v-if="goldPackage[item.value]"
                                   />
                                   <div
                                        v-for="(coin, kCoin) in goldPackage[
                                             item.value
                                        ]"
                                        :key="kCoin"
                                        :class="
                                             item.value == 1
                                                  ? 'bg-background-4'
                                                  : item.value == 2
                                                    ? 'bg-primary-4'
                                                    : item.value == 3
                                                      ? 'bg-primary-1'
                                                      : ''
                                        "
                                        class="px-3 sm:px-5 md:px-7 py-3 sm:py-5 md:py-7 flex flex-row justify-between w-full rounded-xl items-center scale-100 hover:scale-105 transition-transform duration-500 cursor-pointer"
                                        @click="
                                             proceedCheckOutPackage(
                                                  coin['id'],
                                                  item.label,
                                                  getSum(
                                                       coin.gold_coins,
                                                       coin.gold_coins_free
                                                  ),
                                                  getSum(
                                                       coin.silver_coins,
                                                       coin.silver_coins_free
                                                  ),
                                                  getPercentage(
                                                       coin.discount,
                                                       coin.net_amount
                                                  )
                                             )
                                        "
                                   >
                                        <div
                                             :class="
                                                  coin.discount != 0
                                                       ? 'xs:items-end'
                                                       : 'xs:items-center'
                                             "
                                             class="flex flex-row gap-5 sm:gap-7 md:gap-10 justify-between items-center w-full"
                                        >
                                             <div
                                                  class="flex flex-col items-center h-full"
                                             >
                                                  <img
                                                       :src="
                                                            '/assets/img/shop/' +
                                                            item.label
                                                                 .split(' ')[0]
                                                                 .toLowerCase() +
                                                            '.png'
                                                       "
                                                       alt=""
                                                       class="xs:w-36 sm:w-52 md:w-56"
                                                  />
                                                  <div
                                                       v-if="coin.discount != 0"
                                                       class="relative w-full"
                                                  >
                                                       <div
                                                            class="flex flex-row justify-start items-center"
                                                       >
                                                            <div
                                                                 class="absolute right-0 bottom-12 xs:bottom-10 sm:bottom-18"
                                                            >
                                                                 <img
                                                                      :src="
                                                                           baseUrl.img +
                                                                           '/assets/img/shop/badge.png'
                                                                      "
                                                                      alt=""
                                                                      class="w-12 xs:w-14 sm:w-18 md:w-24 h-12 xs:h-14 sm:h-18 md:h-24"
                                                                 />
                                                                 <div
                                                                      class="absolute top-0 w-full h-full flex justify-center items-center text-white font-roboto font-bold text-xs xs:text-xs sm:text-lg"
                                                                 >
                                                                      -{{
                                                                           coin.discount
                                                                      }}%
                                                                 </div>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div class="flex flex-col">
                                                  <div class="flex flex-col">
                                                       <p
                                                            class="uppercase lg:px-10 xl:px-10 2xl:px-16 text-secondary-7 font-helvetica tracking-wide text-center text-2xl xs:text-3xl sm:text-5xl leading-2xl xs:leading-3xl sm:leading-5xl"
                                                       >
                                                            {{
                                                                 money(
                                                                      getSum(
                                                                           coin.gold_coins,
                                                                           coin.gold_coins_free
                                                                      )
                                                                 )
                                                            }}
                                                            GC
                                                       </p>
                                                       <p
                                                            class="text-tertiary-3 font-helvetica tracking-wide font-medium text-center text-sm sm:text-2xl"
                                                       >
                                                            +FREE
                                                            {{
                                                                 money(
                                                                      getSum(
                                                                           coin.silver_coins,
                                                                           coin.silver_coins_free
                                                                      )
                                                                 )
                                                            }}
                                                            SC
                                                       </p>
                                                  </div>
                                                  <div
                                                       class="w-full mt-3 sm:mt-5 md:mt-8 bg-secondary-3 rounded-full py-2 lg:px-4 xl:px-8 2xl:px-10 font-helvetica tracking-wide text-center text-sm sm:text-2xl cursor-pointer"
                                                  >
                                                       <span
                                                            v-if="
                                                                 coin.discount !=
                                                                 0
                                                            "
                                                            class="text-secondary-5 line-through mr-2"
                                                            >${{
                                                                 money(
                                                                      coin.net_amount
                                                                 )
                                                            }}</span
                                                       >
                                                       <span
                                                            class="text-primary-1"
                                                            >${{
                                                                 money(
                                                                      getPercentage(
                                                                           coin.discount,
                                                                           coin.net_amount
                                                                      )
                                                                 )
                                                            }}</span
                                                       >
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
               <div
                    v-else-if="isSelfExclusion != null"
                    class="min-h-screen -mt-16 flex justify-center items-center"
               >
                    <SelfExclusionAlert />
               </div>
          </div>
     </div>
     <CheckoutPayment v-if="isUser" />
     <BankWallet v-if="isUser" />
     <UpdateWallet v-if="isUser" />
     <RemoveWallet v-if="isUser" />
</template>
<script>
import { computed, onMounted, watch } from "vue";
import { Head as InertiaHead } from "@inertiajs/vue3";
import _ from "underscore";
import { useFormatter } from "composables/use-formatter.js";
import { coinsStore } from "store/coins";
import { ref } from "vue";
import { useScroll } from "composables/use-scroll.js";
import SelfExclusionAlert from "components/selfExclusionAlert/index.vue";
import Slider from "@vueform/slider";
import "@vueform/slider/themes/default.css";
import { baseUrl } from "config/services.js";
import CheckoutPayment from "components/modals/checkoutPayment/index.vue";
import eventBus from "plugins/event.js";
import { utilitiesStore } from "store/utilities.js";
import BankWallet from "components/modals/bankWallet.vue";
import UpdateWallet from "components/modals/updateWallet.vue";
import RemoveWallet from "components/modals/closeWallet.vue";
import { useKYC } from "composables/use-kyc.js";
import { cardStore } from "store/cards.js";
import { authStore } from "store/auth.js";
import { useRouter } from "vue-router";
export default {
     components: {
          InertiaHead,
          SelfExclusionAlert,
          Slider,
          CheckoutPayment,
          BankWallet,
          UpdateWallet,
          RemoveWallet
     },
     setup() {
          const { open_kyc_get_coins } = useKYC();

          const router = useRouter();

          const { money } = useFormatter();
          const { scrollTop } = useScroll();

          const goldPackage = ref(null);

          const useUtilitiesStore = utilitiesStore();
          const useCoinsStore = coinsStore();
          const useCardStore = cardStore();
          const useAuthStore = authStore();

          const sliderVal = ref(5);

          const interval = ref(null);

          const isUser = computed(() => {
               return useAuthStore.user;
          });

          const coins = computed(() => {
               if (useCoinsStore.coins?.coins) {
                    const group = _.groupBy(
                         useCoinsStore.coins?.coins,
                         function (num) {
                              return Math.floor(num.type);
                         }
                    );

                    goldPackage.value = group;
               }
               return useCoinsStore.coins || null;
          });

          const isSelfExclusion = computed(() => {
               return useAuthStore.isSelfExclusion;
          });

          // const goTo = (id, type) => {
          //      router.push({ path: "/payment/" + id, query: { action: 'package', type: type } });
          // };

          // const goToWithQuery = (gc, sc, total) => {
          //      router.push({ path: "/payment", query: { action: 'custom', gc: gc, sc: sc, total: total } });
          // };

          const getPercentage = (discount, amount) => {
               return amount - (discount / 100) * amount;
          };

          const getSum = (coins, free_coins) => {
               return parseFloat(coins) + parseFloat(free_coins);
          };

          const minusAmount = (e) => {
               e.stopPropagation();
               e.preventDefault();
               if (sliderVal.value > 5) {
                    sliderVal.value = sliderVal.value - 1;
               }
          };

          const addAmount = (e) => {
               e.stopPropagation();
               e.preventDefault();
               if (sliderVal.value < 5000) {
                    sliderVal.value = sliderVal.value + 1;
               }
          };

          const onHold = (e, type) => {
               if (type == "add") {
                    interval.value = setInterval(() => {
                         addAmount(e);
                    }, 100);
                    return;
               }

               interval.value = setInterval(() => {
                    minusAmount(e);
               }, 100);
          };

          const stopIncrement = () => {
               clearInterval(interval.value);
          };

          const onChangeSlider = () => {
               // console.log(value);
          };

          const openPaymentCheckout = (coin) => {
               eventBus.emit("open:payment_checkout", coin);
          };

          const closePaymentCheckout = () => {
               eventBus.emit("close:payment_checkout", null);
          };

          const proceedCheckOutPackage = (id, type, gc, sc, total) => {
               console.log(isUser.value == null);
               console.log(open_kyc_get_coins() == true);

               if (isUser.value == null) {
                    useUtilitiesStore.setMenuGuest(false);
                    router.push({ path: "/signin", force: true });
                    return;
               }

               if (open_kyc_get_coins() == true) {
                    return;
               }

               const coin = {
                    coin_id: id,
                    action: "package",
                    type: type,
                    gc: gc,
                    sc: sc,
                    total: total
               };

               openPaymentCheckout(coin);
          };

          const proceedCheckOutCustom = (gc, sc, total) => {
               if (isUser.value == null) {
                    useUtilitiesStore.setMenuGuest(false);
                    router.push({ path: "/signin", force: true });
                    return;
               }

               if (open_kyc_get_coins() == true) {
                    return;
               }

               const coin = {
                    action: "custom",
                    gc: gc,
                    sc: sc,
                    total: total
               };

               openPaymentCheckout(coin);
          };

          onMounted(async () => {
               scrollTop();
               await useCoinsStore.getCoins();

               if (isUser.value) {
                    await useCardStore.getBankStatus();
                    await useUtilitiesStore.getState();
               }
          });

          watch(coins, (newVal) => {
               if (newVal) {
                    if (newVal?.coins) {
                         const group = _.groupBy(newVal?.coins, function (num) {
                              return Math.floor(num.type);
                         });

                         goldPackage.value = group;
                    }
               }
          });

          return {
               coins,
               money,
               // goTo,
               // goToWithQuery,
               goldPackage,
               getPercentage,
               getSum,
               sliderVal,
               minusAmount,
               addAmount,
               onHold,
               stopIncrement,
               onChangeSlider,
               baseUrl,
               openPaymentCheckout,
               closePaymentCheckout,
               proceedCheckOutPackage,
               proceedCheckOutCustom,
               isSelfExclusion,
               isUser
          };
     }
};
</script>

import {
     paymentDetails as paymentDetailsAPI,
     getCreditCard as getCreditCardAPI
} from "api/payment.js";
import { bankStatus as bankStatusAPI } from "api/transaction.js";
import { defineStore } from "pinia";

export const cardStore = defineStore("cards", {
     state: () => {
          return {
               cards: null,
               cardsRedeem: null,
               bankStatus: null
          };
     },
     actions: {
          getCards(isForce = false) {
               if (this.cards != null && !isForce) {
                    return;
               }
               return new Promise((resolve, reject) => {
                    paymentDetailsAPI()
                         .then((response) => {
                              this.$patch((state) => {
                                   state.cards = response.list;
                              });
                              resolve();
                         })
                         .catch((error) => {
                              // errorToast('Error to load data!', "top-right");
                              console.error(
                                   "Error to load testimonials data!",
                                   error
                              );
                              reject(error);
                         });
               });
          },
          getCardsRedeem(isForce = false) {
               if (this.cardsRedeem != null && !isForce) {
                    return;
               }
               return new Promise((resolve, reject) => {
                    getCreditCardAPI()
                         .then((response) => {
                              this.$patch((state) => {
                                   state.cardsRedeem = response.list;
                              });
                              resolve();
                         })
                         .catch((error) => {
                              // errorToast('Error to load data!', "top-right");
                              console.error(
                                   "Error to load testimonials data!",
                                   error
                              );
                              reject(error);
                         });
               });
          },
          getBankStatus() {
               return new Promise((resolve, reject) => {
                    bankStatusAPI()
                         .then((response) => {
                              this.$patch((state) => {
                                   state.bankStatus = response;
                              });
                              resolve();
                         })
                         .catch((error) => {
                              // errorToast('Error to load data!', "top-right");
                              this.$patch((state) => {
                                   state.bankStatus = false;
                              });
                              console.error(
                                   "Error to load testimonials data!",
                                   error
                              );
                              reject(error);
                         });
               });
          }
     }
});

<template>
     <div
          v-if="isSelfExclusion == null && coin != null"
          class="bg-white w-full flex flex-col justify-start rounded-b-xl lg:rounded-bl-none rounded-r-none lg:rounded-r-xl"
          style="min-height: 400px"
     >
          <div class="flex flex-row gap-2">
               <div
                    v-if="isActionAccessible('dp_card_button')"
                    @click="tabs = 'credit'"
                    class="hover:bg-background-3 hover:text-white font-helveticaLight tracking-wide rounded-full px-8 py-2 cursor-pointer transition-colors duration-300"
                    :class="
                         tabs == 'credit'
                              ? 'bg-background-3 text-white'
                              : 'bg-white text-background-3 font-bold'
                    "
               >
                    Credit Card
               </div>
               <div
                    v-if="isActionAccessible('dp_bank_button')"
                    @click="tabs = 'wallet'"
                    class="hover:bg-background-3 hover:text-white font-helveticaLight tracking-wide rounded-full px-8 py-2 cursor-pointer transition-colors duration-300"
                    :class="
                         tabs == 'wallet'
                              ? 'bg-background-3 text-white'
                              : 'bg-white text-background-3 font-bold'
                    "
               >
                    Bank Transfer
               </div>
          </div>
          <CreditCard
               v-if="tabs == 'credit' && isActionAccessible('dp_card_button')"
               :coins="coin"
               :setLoading="setLoading"
               :cardDetails="cardDetails"
          />
          <Wallet
               v-if="tabs == 'wallet' && isActionAccessible('dp_bank_button')"
               :tabs="tabs"
               :coins="coin"
               :setLoading="setLoading"
          />
     </div>
     <SelfExclusionAlert
          :lightMode="true"
          v-else-if="isSelfExclusion != null"
     />

     <paymentSuccess :successCoins="paymentSuccessCoins" />
     <paymentFailed />
     <loading-overlay :isLoading="loading" />
</template>
<script>
import { onMounted, reactive, ref, computed, watch } from "vue";
import { useFormatter } from "composables/use-formatter.js";
import { useRouter, useRoute } from "vue-router";
import LoadingOverlay from "components/loading/loadingOverlay.vue";
import paymentSuccess from "components/modals/paymentSuccess.vue";
import paymentFailed from "components/modals/paymentFailed.vue";
import CreditCard from "./component/credit.vue";
import Wallet from "./component/wallet.vue";
import { utilitiesStore } from "store/utilities.js";
import { cardStore } from "store/cards.js";
import SelfExclusionAlert from "components/selfExclusionAlert/index.vue";
import { useBank } from "composables/use-bank.js";
import { authStore } from "store/auth.js";

export default {
     components: {
          LoadingOverlay,
          paymentSuccess,
          paymentFailed,
          CreditCard,
          Wallet,
          SelfExclusionAlert
     },
     setup() {
          const { isActionAccessible } = useBank();
          const router = useRouter();
          const route = useRoute();
          const { money } = useFormatter();

          const useUtilitiesStore = utilitiesStore();
          const useCardStore = cardStore();
          const useAuthStore = authStore();

          const loading = ref(false);

          const tabs = ref(
               route.query.panel
                    ? "credit"
                    : isActionAccessible("dp_card_button")
                      ? "credit"
                      : "wallet"
          );

          const isSelfExclusion = computed(() => {
               return useAuthStore.isSelfExclusion;
          });

          const cardDetails = computed(() => {
               return useCardStore.cards ?? [];
          });

          const bankStatus = computed(() => {
               if (useCardStore.bankStatus) {
                    setActiveTab(useCardStore.bankStatus);
               }
               return useCardStore.bankStatus;
          });

          const coin = computed(() => {
               return useUtilitiesStore.selected_coin;
          });

          const paymentSuccessCoins = reactive({
               gold_coins: 0,
               silver_coins: 0
          });

          const goTo = () => {
               router.push({ path: "/gold-coin-shop" });
          };

          const setLoading = (data) => {
               loading.value = data;
          };

          const fetchGetCoins = async () => {
               await useUtilitiesStore.getCoins({
                    id: route.path.split("/")[2]
               });
          };

          async function setPaymentSuccessCoins(res) {
               paymentSuccessCoins.gold_coins = money(res.gold_coins);
               paymentSuccessCoins.silver_coins = money(res.silver_coins);
          }

          const getPercentage = (discount, amount) => {
               return money(amount - (discount / 100) * amount);
          };

          const setActiveTab = (newVal) => {
               const filter = _.filter(newVal, function (params) {
                    return ["dp_bank_button", "dp_card_button"].includes(
                         params["slug"]
                    );
               });

               if (filter?.length != 0) {
                    const bank = _.filter(newVal, function (params) {
                         return params["slug"] == "dp_bank_button";
                    });

                    const card = _.filter(newVal, function (params) {
                         return params["slug"] == "dp_card_button";
                    });

                    if (card[0].note == 1) {
                         paymentMethod.value = "credit";
                         return;
                    } else if (bank[0].note == 1) {
                         paymentMethod.value = "wallet";
                         return;
                    }
                    return;
               }

               paymentMethod.value = "credit";
          };

          onMounted(async () => {
               window.scrollTo({
                    top: 0,
                    behavior: "smooth"
               });
               await fetchGetCoins();
               await useCardStore.getBankStatus();
               await useUtilitiesStore.getState();
               await useCardStore.getCards();
          });

          watch(bankStatus, (newVal) => {
               if (newVal) {
                    setActiveTab(newVal);
               }
          });

          return {
               goTo,
               coin,
               money,
               tabs,
               loading,
               setLoading,
               paymentSuccessCoins,
               setPaymentSuccessCoins,
               cardDetails,
               getPercentage,
               isActionAccessible,
               isSelfExclusion
          };
     }
};
</script>

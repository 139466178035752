<template>
     <div class="w-full">
          <div
               v-if="!isAddNewCard"
               class="font-roboto"
          >
               <p
                    class="text-shade-8 text-xs md:text-sm lg:text-xs xl:text-sm my-5"
               >
                    Connect your credit account for easier payment setup with no
                    expiration and faster refunds.
               </p>
               <p
                    class="text-xs md:text-sm lg:text-xs xl:text-sm my-5 text-black font-bold"
               >
                    Current account
               </p>
               <div
                    v-if="cardDetails != null"
                    class="grid grid-cols-1 lg:grid-cols-2 gap-3"
               >
                    <div
                         v-for="(item, key) in cardDetails"
                         :key="key"
                         class="w-full"
                    >
                         <div
                              @click="setSelectedCard(item)"
                              class="flex flex-row items-end mb-4 w-full gap-2 px-4 py-3 bg-gray-150 border-2 border-solid rounded-lg cursor-pointer"
                              :class="
                                   selectedCard?.id == item.id
                                        ? 'border-2 border-background-7'
                                        : 'border border-shade-10'
                              "
                         >
                              <div class="flex flex-row w-full">
                                   <span
                                        class="w-full cursor-pointer flex flex-col justify-end items-start text-black text-xs md:text-sm lg:text-xs xl:text-sm"
                                   >
                                        <img
                                             :class="
                                                  item.paypage?.type == 'VI'
                                                       ? ''
                                                       : 'hidden'
                                             "
                                             :src="
                                                  baseUrl.img +
                                                  '/assets/img/card/visa-rounded.png'
                                             "
                                             class="h-8"
                                        />
                                        <span class="capitalize">{{
                                             item.paypage?.card_name
                                        }}</span>
                                        Account ending in
                                        <span class="font-bold">{{
                                             item.paypage?.lastFour
                                        }}</span>
                                   </span>
                              </div>
                              <div class="flex h-full">
                                   <span
                                        @click="openDeleteCC(item.id)"
                                        class="cursor-pointer"
                                   >
                                        <span
                                             class="underline underline-offset-2 cursor-pointer flex flex-col justify-end items-start text-black text-xs md:text-sm lg:text-xs xl:text-sm"
                                        >
                                             Remove
                                        </span>
                                   </span>
                              </div>
                         </div>
                    </div>

                    <div>
                         <p
                              v-for="(item, key) in Object.keys(
                                   payment?.errors?.errors
                              )"
                              :key="key"
                              class="mt-1 text-sm text-red-600 text-xs text-primal-red ease-in-out transition-all duration-300 font-inter text-right w-full"
                         >
                              {{ payment?.errors?.errors[item][0] }}
                         </p>
                    </div>
               </div>
               <div
                    v-else-if="cardDetails == null"
                    class="h-full w-full"
               >
                    <div
                         v-if="loading"
                         class="flex item-center justify-center w-full py-56"
                    >
                         <svg
                              aria-hidden="true"
                              class="w-8 h-8 text-black animate-spin fill-secondary-1"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                         >
                              <path
                                   d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                   fill="currentColor"
                              ></path>
                              <path
                                   d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                   fill="currentFill"
                              ></path>
                         </svg>
                    </div>
               </div>
               <div>
                    <span
                         @click="addNewCard()"
                         class="font-bold underline text-base cursor-pointer"
                    >
                         <span class="text-lg">+</span>
                         <span
                              class="text-sm sm:text-base md:text-lg lg:text-base xl:text-lg"
                         >
                              Add New Card
                         </span>
                    </span>
               </div>
               <div class="flex flex-col mt-32 w-full">
                    <p
                         class="text-shade-8 text-xs md:text-sm lg:text-xs xl:text-sm my-5"
                    >
                         Moozi accepts the following credit cards and debit
                         cards:
                    </p>
                    <div
                         class="flex flex-row gap-2 items-center justify-center w-full mb-5"
                    >
                         <div class="flex flex-row gap-2 items-center">
                              <p
                                   class="text-shade-7 text-xs md:text-sm lg:text-xs xl:text-sm my-5"
                              >
                                   Credit card
                              </p>
                              <img
                                   class="h-6 lg:h-8"
                                   :src="
                                        baseUrl.img +
                                        '/assets/img/card/visa-rounded.png'
                                   "
                                   alt="visa"
                              />
                         </div>
                         <div class="flex flex-row gap-2 items-center">
                              <p
                                   class="text-shade-7 text-xs md:text-sm lg:text-xs xl:text-sm my-5"
                              >
                                   Or
                              </p>
                         </div>
                         <div class="flex flex-row gap-2 items-center">
                              <p
                                   class="text-shade-7 text-xs md:text-sm lg:text-xs xl:text-sm my-5"
                              >
                                   Debit card
                              </p>
                              <img
                                   class="h-6 lg:h-8"
                                   :src="
                                        baseUrl.img +
                                        '/assets/img/card/visa-rounded.png'
                                   "
                                   alt="visa"
                              />
                              <img
                                   class="h-6 lg:h-8"
                                   :src="
                                        baseUrl.img +
                                        '/assets/img/card/discover-rounded.png'
                                   "
                                   alt="discover"
                              />
                              <img
                                   class="h-6 lg:h-8"
                                   :src="
                                        baseUrl.img +
                                        '/assets/img/card/master-rounded.png'
                                   "
                                   alt="master"
                              />
                         </div>
                    </div>
                    <transition
                         enter-from-class="opacity-0"
                         enter-active-class="transition ease-in duration-300"
                         enter-to-class="opacity-100 scale-100"
                    >
                         <div
                              v-if="limitReachValue > dailyLimit"
                              class="rounded-xl !mt-0 bg-primal-yellow text-shade-19 flex flex-row items-center gap-2 ease-in-out transition-all duration-300 p-3 mb-4 w-full"
                         >
                              <svg
                                   class="w-5 h-5"
                                   viewBox="0 0 20 20"
                                   fill="none"
                                   xmlns="http://www.w3.org/2000/svg"
                              >
                                   <path
                                        d="M10 7V11M10 14V14.01M1 10C1 11.1819 1.23279 12.3522 1.68508 13.4442C2.13738 14.5361 2.80031 15.5282 3.63604 16.364C4.47177 17.1997 5.46392 17.8626 6.55585 18.3149C7.64778 18.7672 8.8181 19 10 19C11.1819 19 12.3522 18.7672 13.4442 18.3149C14.5361 17.8626 15.5282 17.1997 16.364 16.364C17.1997 15.5282 17.8626 14.5361 18.3149 13.4442C18.7672 12.3522 19 11.1819 19 10C19 8.8181 18.7672 7.64778 18.3149 6.55585C17.8626 5.46392 17.1997 4.47177 16.364 3.63604C15.5282 2.80031 14.5361 2.13738 13.4442 1.68508C12.3522 1.23279 11.1819 1 10 1C8.8181 1 7.64778 1.23279 6.55585 1.68508C5.46392 2.13738 4.47177 2.80031 3.63604 3.63604C2.80031 4.47177 2.13738 5.46392 1.68508 6.55585C1.23279 7.64778 1 8.8181 1 10Z"
                                        stroke="#FFF2B1"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                   />
                              </svg>
                              <p class="text-sm lg:text-base font-medium">
                                   You have already exceeded your set purchase
                                   limit.
                              </p>
                         </div>
                    </transition>
                    <transition
                         enter-from-class="opacity-0"
                         enter-active-class="transition ease-in duration-300"
                         enter-to-class="opacity-100 scale-100"
                    >
                         <div
                              v-if="checkActionRestricted()"
                              class="rounded-xl !mt-0 bg-primal-yellow text-shade-19 flex flex-row items-center gap-2 ease-in-out transition-all duration-300 p-3 mb-4 w-full"
                         >
                              <svg
                                   class="w-5 h-5"
                                   viewBox="0 0 20 20"
                                   fill="none"
                                   xmlns="http://www.w3.org/2000/svg"
                              >
                                   <path
                                        d="M10 7V11M10 14V14.01M1 10C1 11.1819 1.23279 12.3522 1.68508 13.4442C2.13738 14.5361 2.80031 15.5282 3.63604 16.364C4.47177 17.1997 5.46392 17.8626 6.55585 18.3149C7.64778 18.7672 8.8181 19 10 19C11.1819 19 12.3522 18.7672 13.4442 18.3149C14.5361 17.8626 15.5282 17.1997 16.364 16.364C17.1997 15.5282 17.8626 14.5361 18.3149 13.4442C18.7672 12.3522 19 11.1819 19 10C19 8.8181 18.7672 7.64778 18.3149 6.55585C17.8626 5.46392 17.1997 4.47177 16.364 3.63604C15.5282 2.80031 14.5361 2.13738 13.4442 1.68508C12.3522 1.23279 11.1819 1 10 1C8.8181 1 7.64778 1.23279 6.55585 1.68508C5.46392 2.13738 4.47177 2.80031 3.63604 3.63604C2.80031 4.47177 2.13738 5.46392 1.68508 6.55585C1.23279 7.64778 1 8.8181 1 10Z"
                                        stroke="#FFF2B1"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                   />
                              </svg>
                              <p class="text-sm lg:text-base font-medium">
                                   This action is currently restricted pls
                                   contact support
                              </p>
                         </div>
                    </transition>
                    <button
                         @click="openConfirmationModal()"
                         class="w-full rounded-full font-bold tracking-wider px-10 py-3 text-primary-1 text-sm sm:text-base md:text-lg lg:text-base xl:text-lg cursor-pointer"
                         :disabled="
                              selectedCard != null &&
                              !checkActionRestricted() &&
                              !(limitReachValue > dailyLimit)
                                   ? false
                                   : true
                         "
                         :class="
                              selectedCard != null &&
                              !checkActionRestricted() &&
                              !(limitReachValue > dailyLimit)
                                   ? 'bg-secondary-3 text-primary-1'
                                   : 'bg-gray-190 text-shade-7'
                         "
                    >
                         Next
                    </button>
               </div>
          </div>
          <form
               @submit.prevent="onSubmit"
               method="post"
               id="fCheckout"
               name="fCheckout"
               onsubmit="return false;"
          >
               <div
                    v-if="isAddNewCard"
                    class="space-y-4 transform ease-in-out transition-all duration-300"
               >
                    <div class="flex items-center justify-between !mb-4">
                         <div class="flex flex-row">
                              <h3
                                   class="mt-4 text-sm md:text-base lg:text-sm xl:text-base text-black font-bold font-roboto"
                              >
                                   Credit card details
                              </h3>
                         </div>
                    </div>
                    <div
                         v-if="cardError != ''"
                         class="bg-primal-red rounded-xl p-4 flex flex-row gap-3 items-center"
                    >
                         <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                         >
                              <path
                                   d="M12 9V13M12 16V16.01M3 12C3 13.1819 3.23279 14.3522 3.68508 15.4442C4.13738 16.5361 4.80031 17.5282 5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3C10.8181 3 9.64778 3.23279 8.55585 3.68508C7.46392 4.13738 6.47177 4.80031 5.63604 5.63604C4.80031 6.47177 4.13738 7.46392 3.68508 8.55585C3.23279 9.64778 3 10.8181 3 12Z"
                                   stroke="white"
                                   stroke-width="2"
                                   stroke-linecap="round"
                                   stroke-linejoin="round"
                              />
                         </svg>
                         <h3 class="text-sm font-medium text-white w-full">
                              {{ cardError }}
                         </h3>
                         <svg
                              @click="cardError = ''"
                              class="cursor-pointer"
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                         >
                              <path
                                   d="M13 1L1 13M1 1L13 13"
                                   stroke="white"
                                   stroke-width="2"
                                   stroke-linecap="round"
                                   stroke-linejoin="round"
                              />
                         </svg>
                    </div>
                    <div class="flex flex-col gap-1">
                         <div class="flex flex-row gap-2 items-center">
                              <input
                                   type="radio"
                                   id="option1"
                                   name="group1"
                                   value="Option1"
                              />
                              <label
                                   for="option1"
                                   class="block text-sm font-medium text-shade-2"
                              >
                                   Add Credit Card
                              </label>
                              <img
                                   class="h-6 lg:h-8"
                                   :src="
                                        baseUrl.img +
                                        '/assets/img/card/visa-rounded.png'
                                   "
                                   alt="visa"
                              />
                         </div>
                         <div class="flex flex-row gap-2 items-center">
                              <input
                                   type="radio"
                                   id="option2"
                                   name="group1"
                                   value="Option2"
                              />
                              <label
                                   for="option1"
                                   class="block text-sm font-medium text-shade-2"
                              >
                                   Add Dedit Card
                              </label>
                              <img
                                   class="h-6 lg:h-8"
                                   :src="
                                        baseUrl.img +
                                        '/assets/img/card/visa-rounded.png'
                                   "
                                   alt="visa"
                              />
                              <img
                                   class="h-6 lg:h-8"
                                   :src="
                                        baseUrl.img +
                                        '/assets/img/card/discover-rounded.png'
                                   "
                                   alt="discover"
                              />
                              <img
                                   class="h-6 lg:h-8"
                                   :src="
                                        baseUrl.img +
                                        '/assets/img/card/master-rounded.png'
                                   "
                                   alt="master"
                              />
                         </div>
                    </div>
                    <div>
                         <label
                              for="card_name"
                              class="block mb-2 text-sm font-medium text-shade-2"
                         >
                              Card holder name
                         </label>
                         <input
                              v-model="payment.card_name"
                              type="text"
                              name="card_name"
                              id="card_name"
                              class="bg-white border border-shade-10 text-shade-2 text-sm rounded-md focus:ring-background-2 focus:border-background-2 block w-full p-2.5 font-normal"
                              placeholder="Enter your full name"
                              autocomplete="card_name"
                              @change="
                                   () => {
                                        clearFormError('card_name');
                                   }
                              "
                              :class="
                                   payment.errors?.errors?.card_name != null
                                        ? 'border-primal-red focus:ring-primal-red focus:border-primal-red'
                                        : 'border-shade-10 focus:ring-background-2 focus:border-background-2'
                              "
                         />
                         <has-error-form
                              v-model:form="payment"
                              :field="'card_name'"
                         ></has-error-form>
                         <div class="checkout">
                              <input
                                   type="hidden"
                                   id="bin"
                                   name="bin"
                                   readOnly="true"
                              />
                              <input
                                   type="hidden"
                                   id="request$merchantTxnId"
                                   name="request$merchantTxnId"
                                   value="10174000"
                              />
                              <input
                                   type="hidden"
                                   id="request$reportGroup"
                                   name="request$reportGroup"
                                   value="Cert30 Merchant Rollup**"
                              />
                              <input
                                   type="hidden"
                                   id="request$timeout"
                                   name="request$timeout"
                                   value="5000"
                              />
                              <input
                                   type="hidden"
                                   id="request$orderId"
                                   name="request$orderId"
                                   value="order_123"
                              />
                              <div
                                   v-if="loadingCard"
                                   class="flex item-center justify-center w-full py-20"
                              >
                                   <svg
                                        aria-hidden="true"
                                        class="w-8 h-8 text-black animate-spin fill-secondary-1"
                                        viewBox="0 0 100 101"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                   >
                                        <path
                                             d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                             fill="currentColor"
                                        ></path>
                                        <path
                                             d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                             fill="currentFill"
                                        ></path>
                                   </svg>
                              </div>
                              <div
                                   id="payframe"
                                   style="width: 100%"
                              ></div>
                         </div>
                    </div>

                    <div class="flex justify-between">
                         <div class="flex items-center">
                              <div class="flex items-center h-5">
                                   <input
                                        id="save_card_detail"
                                        type="checkbox"
                                        :checked="payment.save_card_detail"
                                        @change="
                                             payment.save_card_detail =
                                                  !payment.save_card_detail
                                        "
                                        class="w-4 h-4 border border-shade-7 rounded bg-shade-1 focus:ring-1 focus:ring-shade-7 text-background-3"
                                   />
                              </div>
                              <label
                                   for="save_card_detail"
                                   class="ms-2 text-sm font-medium text-shade-11 leading-tight"
                              >
                                   Save this payment method
                              </label>
                         </div>
                    </div>

                    <!-- <div class="flex items-center justify-start">
                         <h3 class="text-sm md:text-base lg:text-sm xl:text-base text-black font-bold font-roboto">Billing address</h3>
                    </div>

                    <div class="flex items-start gap-3">
                         <div class="flex-1">
                              <label for="province_id" class="block mb-2 text-sm font-medium text-shade-2">State</label>
                              <searchableSelect
                                   :clearFormErrorFunction="clearFormError"
                                   :form="payment"
                                   :options="state"
                                   v-model:selectedOption="stateVal"
                                   :optionLabel="'province_name'"
                                   :optionValue="'id'"
                                   :keyValue="'billing.' + 'state'"
                                   :placeholder="'Select state'"
                              />
                              <has-error-form v-model:form="payment" :field="'billing.state'"></has-error-form>
                         </div>
                         <div class="flex-1">
                              <label for="city_id" class="block mb-2 text-sm font-medium text-shade-2">City</label>
                              <searchableSelect
                                   :clearFormErrorFunction="clearFormError"
                                   :form="payment" :options="city"
                                   v-model:selectedOption="cityVal" :optionLabel="'city_name'" :optionValue="'city_name'"
                                   :keyValue="'billing.' + 'city'" :placeholder="'Select city'"
                              />
                              <has-error-form v-model:form="payment" :field="'billing.city'"></has-error-form>
                         </div>
                         <div class="flex-1">
                              <label for="zip_code" class="block mb-2 text-sm font-medium text-shade-2">ZIP code</label>
                              <searchableSelect
                                   :clearFormErrorFunction="clearFormError"
                                   :form="payment"
                                   :options="zipCode"
                                   v-model:selectedOption="payment.billing.zipcode"
                                   :optionLabel="'zip_code'"
                                   :optionValue="'zip_code'"
                                   :keyValue="'billing.' + 'zipcode'"
                                   :placeholder="'Zip Code'"
                              />
                              <has-error-form v-model:form="payment" :field="'billing.zipcode'"></has-error-form>
                         </div>
                    </div>

                    <div>
                         <label for="address_1" class="block mb-2 text-sm font-medium text-shade-2">Address line</label>
                         <input
                              v-model="payment.billing.address_1" type="text" name="address_1" id="address_1"
                         class="bg-white border border-shade-10 text-shade-2 text-sm rounded-md focus:ring-background-2 focus:border-background-2 block w-full p-2.5 font-normal"
                         placeholder="Enter your address" autocomplete="address_1" :class="payment.errors?.errors?.address_1 != null
                              ? 'border-primal-red focus:ring-primal-red focus:border-primal-red'
                              : 'border-shade-10 focus:ring-background-2 focus:border-background-2'
                              " />
                         <has-error-form v-model:form="payment" :field="'billing'"></has-error-form>
                    </div> -->

                    <div>
                         <div
                              class="flex flex-wrap w-full gap-4 mt-4 sm:mt-6 md:mt-8"
                         >
                              <button
                                   @click="cancelButton()"
                                   class="flex-1 w-full text-primary-1 bg-gray-190 px-4 py-3 xl:py-3.5 text-center leading-tight shadow-xl rounded-full font-bold"
                              >
                                   Cancel
                              </button>
                              <button
                                   id="submitButton"
                                   type="submit"
                                   class="flex-1 w-full text-primary-1 bg-secondary-3 px-4 py-3 xl:py-3.5 text-center leading-tight shadow-xl rounded-full font-bold"
                              >
                                   Next
                              </button>
                         </div>
                    </div>
               </div>
          </form>
     </div>
     <creditConfirmation
          :onClickPurchaseCoin="submitPurchaseCoinApi"
          :selectedCard="selectedCard"
          :wpResponse="wp_response"
          :payment="payment"
          :coins="coins"
          :purchase_loading="purchase_loading"
          :initRecaptcha="initRecaptcha"
     />
     <creditDeleteConfirmation
          :id="deleteCardID"
          :selectedCard="selectedCard"
          :coins="coins"
     />
</template>
<script>
import { computed, onMounted, reactive, ref, toRefs, watch } from "vue";
import eventBus from "plugins/event.js";
import { utilitiesStore } from "store/utilities.js";
import { useFormatter } from "composables/use-formatter.js";
import { purchaseCoin as purchaseCoinAPI } from "api/payment.js";
import Form from "vform";
import { useToaster } from "composables/use-toaster.js";
import HasErrorForm from "components/haserror/index.vue";
import { cardStore } from "store/cards";
import creditConfirmation from "components/modals/creditConfirmation.vue";
import creditDeleteConfirmation from "components/modals/creditDeleteConfirmation.vue";
import { eprotectUrl } from "config/services.js";
import { coinsStore } from "store/coins";
import { baseUrl } from "config/services.js";
import { useKYC } from "composables/use-kyc.js";
import { useActionRestriction } from "composables/use-restriction.js";

export default {
     props: [
          "coins",
          "setLoading",
          "setPaymentSuccessCoins",
          "limitReachValue",
          "dailyLimit",
          "closeModal"
     ],
     components: {
          HasErrorForm,
          creditConfirmation,
          creditDeleteConfirmation
     },
     setup(props) {
          const { open_kyc_bank } = useKYC();
          const { isActionRestricted } = useActionRestriction();

          const initRecaptcha = ref(false);

          const { errorToast } = useToaster();
          const { money } = useFormatter();
          const useUtilitiesStore = utilitiesStore();
          const useCardStore = cardStore();
          const useCoinsStore = coinsStore();

          const selectedCard = ref(null);
          const showCVV = ref(false);
          const formDisable = ref(false);

          const cardError = ref("");

          const errorCard = ref(null);

          const deleteCardID = ref(null);

          const payframeClientLoaded = ref(null);

          const isAddNewCard = ref(false);

          const { coins } = toRefs(props);

          const loadingCard = ref(false);

          const payment = reactive(
               new Form({
                    card_name: "",
                    // billing: {
                    //      address_1: "",
                    //      address_2: "",
                    //      state: "",
                    //      city: "",
                    //      zipcode: "",
                    // },
                    coin_id: null,
                    payment_category: "CARD",
                    save_card_detail: false,
                    test_void: false,
                    authentication_test: false
               })
          );

          const startTime = ref(null);

          const loading = ref(false);

          const purchase_loading = ref(false);

          const wp_response = ref(null);

          const cardDetails = computed(() => {
               return useCardStore.cards ?? [];
          });

          const worldpayStatus = computed(() => {
               return useCoinsStore.worldpayStatus == true ? "prod" : "dev";
          });

          // const getState = (ST) => {

          //      const filterState = _.find(state.value, function (params) {
          //           return params["province_name"].toLowerCase() == ST.toLowerCase();
          //      })

          //      return filterState;
          // }

          const submitPurchaseCoinApi = async () => {
               // const location = getState(payment.billing.state);

               purchase_loading.value = true;
               var params = {};

               if (selectedCard.value) {
                    params["card_id"] = selectedCard.value?.id;
               } else {
                    // const billingTemp = payment.billing;
                    // billingTemp['state'] = location != null ? location['province_name_abbr'] : '';

                    params = {
                         card_name: payment.card_name,
                         // billing: payment.billing,
                         payment_category: payment.payment_category,
                         coin_id: coins.value["coin_id"]
                    };

                    if (wp_response.value?.message != "Success") {
                         purchase_loading.value = false;
                         return;
                    } else {
                         params["paypageRegistrationId"] =
                              wp_response.value?.paypageRegistrationId;
                         (params["card_expiration"] =
                              wp_response.value?.expMonth &&
                              wp_response.value?.expYear
                                   ? wp_response.value?.expMonth +
                                     "/" +
                                     wp_response.value?.expYear
                                   : ""),
                              (params["card_cvv"] = "");
                         params["type"] = wp_response.value?.type;
                         params["vantivTxnId"] = wp_response.value?.vantivTxnId;
                         params["paypageId"] = wp_response.value?.id;
                         params["firstSix"] = wp_response.value?.firstSix;
                         params["lastFour"] = wp_response.value?.lastFour;
                         params["bin"] = wp_response.value?.bin;

                         if (payment.save_card_detail) {
                              params["save_card_detail"] =
                                   payment.save_card_detail == true ? 1 : 0;
                         }
                         if (payment.test_void) {
                              params["void_test"] =
                                   payment.test_void == true ? 1 : 0;
                         }
                         if (payment.authentication_test) {
                              params["authenticationValue"] =
                                   payment.authentication_test == true ? 1 : 0;
                         }
                    }
               }

               if (coins.value["action"] == "package") {
                    params["coin_id"] = coins.value["coin_id"];
               } else {
                    params["money"] = parseInt(coins.value["total"]);
               }

               await purchaseCoinAPI(params)
                    .then((res) => {
                         if (selectedCard.value) {
                              useUtilitiesStore.enableScroll();
                              purchase_loading.value = false;
                              closeConfirmationModal();
                              props.closeModal();
                              openPaymentSuccess({
                                   gc: res.gold_coins,
                                   sc: res.silver_coins
                              });
                         } else {
                              errorCard.value = null;
                              payment.reset();
                              payment.errors.errors = {};
                              // payment.coin_id = route.path.split("/")[2];
                              useCardStore.getCards(true);
                              isAddNewCard.value = false;
                              useUtilitiesStore.enableScroll();
                              purchase_loading.value = false;
                              closeConfirmationModal();
                              props.closeModal();
                              openPaymentSuccess({
                                   gc: res.gold_coins,
                                   sc: res.silver_coins
                              });
                         }
                    })
                    .catch(async ({ response }) => {
                         if (selectedCard.value) {
                              props.closeModal();
                              openPaymentFailed();
                         } else {
                              const { data, status, message } = response;
                              if (status == 403) {
                                   await useCardStore.getBankStatus();
                                   errorToast(data.message, "top-right");
                              } else if (status == 422) {
                                   // console.log(response);
                                   if (data.message != undefined) {
                                        if (data.message) {
                                             errorToast(
                                                  data.message,
                                                  "top-right"
                                             );
                                             payment.errors.errors =
                                                  data?.errors;
                                             //   errorCard.value = data.message;
                                        } else {
                                             errorToast(message, "top-right");
                                             //   errorCard.value = message;
                                        }
                                   } else {
                                        errorToast(
                                             data[Object.keys(data)[0][0]],
                                             "top-right"
                                        );
                                        // errorCard.value = data[Object.keys(data)[0][0]];
                                        payment.errors.errors = data?.errors;
                                   }
                              } else {
                                   if (
                                        !data.status &&
                                        data["status"] != undefined
                                   ) {
                                        errorToast(data.message, "top-right");
                                   }
                              }
                         }
                         closeConfirmationModal();
                         props.closeModal();
                         purchase_loading.value = false;
                    });
          };

          const onSubmit = async () => {
               props.setLoading(true);
               if (payframeClientLoaded.value.allInputsEmpty()) {
                    props.setLoading(false);
                    return false;
               }

               var message = {
                    id: document.getElementById("request$merchantTxnId").value,
                    orderId: document.getElementById("request$orderId").value
               };

               startTime.value = new Date().getTime();
               payframeClientLoaded.value.getPaypageRegistrationId(message);
               return false;
          };

          const clearFormError = (key) => {
               if (typeof payment.errors.errors[key] !== "undefined") {
                    delete payment.errors.errors[key];
               }
          };

          const openPaymentSuccess = (coins) => {
               eventBus.emit("open:payment_success", coins);
          };

          const openPaymentFailed = () => {
               eventBus.emit("open:payment_failed");
          };

          const openDeleteCC = (id) => {
               deleteCardID.value = id;
               eventBus.emit("open:deleteconfirmation");
          };

          const payframeClientCallback = async (response) => {
               // console.log(response);
               if (response.timeout) {
                    var elapsedTime = new Date().getTime() - startTime.value;
                    // document.getElementById("timeoutMessage").value =
                    //   "Timed out after " + elapsedTime + "ms";
                    errorToast(
                         "Timed out after " + elapsedTime + "ms",
                         "top-right"
                    );
                    // errorCard.value = "Timed out after " + elapsedTime + "ms";
                    props.setLoading(false);
               } else {
                    if (response.response === "870") {
                         if (response) {
                              wp_response.value = response;
                              openConfirmationModal();
                              props.setLoading(false);
                         }
                    } else if (
                         response.response === "871" ||
                         response.response === "872" ||
                         response.response === "873" ||
                         response.response === "874" ||
                         response.response === "876"
                    ) {
                         errorToast(
                              response?.message
                                   ? response.message
                                   : "Please check and re-enter your credit card number and try again.",
                              "top-right"
                         );
                         //   errorCard.value = response?.message
                         //     ? response.message
                         //     : "Please check and re-enter your credit card number and try again.";
                         props.setLoading(false);
                    } else if (
                         response.response === "881" ||
                         response.response === "882" ||
                         response.response === 883
                    ) {
                         errorToast(
                              response?.message
                                   ? response.message
                                   : "Please check and re-enter your credit card number and try again.",
                              "top-right"
                         );

                         //   errorCard.value = response?.message
                         //     ? response.message
                         //     : "Please check and re-enter your credit card number and try again.";
                         props.setLoading(false);
                    } else if (response.response === "884") {
                         //  document.getElementById("payframe").style.display =
                         //       "none";
                         document.getElementById("submitButton").disabled =
                              true;
                         props.setLoading(false);
                    } else {
                         errorToast(
                              "We are experiencing technical difficulties. Please try again or contact us to complete your order",
                              "top-right"
                         );
                         //   errorCard.value =
                         //     "We are experiencing technical difficulties. Please try again or contact us to complete your order";
                         props.setLoading(false);
                    }
               }
          };

          const inputsEmptyCallback = (res) => {
               var isEmpty = res.allInputsEmpty;
               if (isEmpty) {
                    errorToast("Card input fields empty", "top-right");
                    // errorCard.value = "Card input fields empty";
                    return true;
               } else {
                    errorCard.value = null;
                    return false;
               }
          };

          const addNewCard = () => {
               if (open_kyc_bank() == true) {
                    return;
               }

               selectedCard.value = null;
               isAddNewCard.value = !isAddNewCard.value;
          };

          const openConfirmationModal = () => {
               initRecaptcha.value = true;
               eventBus.emit("open:creditconfirmation");
          };

          const closeConfirmationModal = () => {
               initRecaptcha.value = false;
               eventBus.emit("close:creditconfirmation");
          };

          const numbersOnly = (evt) => {
               evt = evt ? evt : window.event;
               var charCode = evt.which ? evt.which : evt.keyCode;
               if (
                    charCode > 31 &&
                    (charCode < 48 || charCode > 57) &&
                    charCode !== 46
               ) {
                    evt.preventDefault();
               } else {
                    return true;
               }
          };

          const setSelectedCard = (card_details) => {
               selectedCard.value = card_details;
          };

          const cancelButton = () => {
               if (cardDetails.value.length) {
                    selectedCard.value = cardDetails.value[0];
               }
               errorCard.value = null;
               payment.reset();
               payment.errors.errors = {};
               isAddNewCard.value = false;
               window.scrollTo({
                    top: 0,
                    behavior: "smooth"
               });
          };

          const checkAPButton = async () => {
               const apButtonTab = document.getElementById("ap-button-tab");
               const apButtonPanel = document.getElementById("ap-button-panel");
               if (apButtonTab) {
                    if (
                         window.ApplePaySession &&
                         ApplePaySession.canMakePayments()
                    ) {
                         apButtonTab.classList.remove("hidden");
                         apButtonPanel?.classList.remove("hidden");
                    } else {
                         apButtonTab.remove();
                         apButtonPanel?.remove();
                    }
               }
          };

          const checkActionRestricted = () => {
               // DEPOSIT
               const restrictedActionType = 1;

               return isActionRestricted(restrictedActionType);
          };

          onMounted(async () => {
               checkAPButton();

               await useCardStore.getCards();

               const isExist1 = document.querySelector(
                    "#eprotect-jquery-import"
               );
               const isExist2 = document.querySelector("#eprotect-import");

               if (isExist1 == null && isExist2 == null) {
                    var script1 = document.createElement("script");
                    script1.id = "eprotect-jquery-import";
                    script1.src =
                         eprotectUrl[worldpayStatus.value][
                              "eprotect-jquery-import"
                         ];
                    document.body.appendChild(script1);

                    var script2 = document.createElement("script");
                    script2.id = "eprotect-import";
                    // https://request.eprotect.vantivprelive.com/eProtect/js/eProtect-iframe-client4.min.js
                    script2.src =
                         eprotectUrl[worldpayStatus.value]["eprotect-import"];
                    document.body.appendChild(script2);
               }

               // openPaymentFailed();
               if (cardDetails.value.length) {
                    selectedCard.value = cardDetails.value[0];
               }
               // payment.coin_id = route.path.split("/")[2];
               loading.value = false;
          });

          watch(selectedCard, async (newVal) => {
               selectedCard.value = newVal;
          });

          // watch(stateVal, async (newVal) => {
          //      if (newVal) {
          //           getCity(newVal);

          //           const filter = _.filter(state.value, (params) => {
          //                return params['id'] == newVal;
          //           })

          //           payment.billing.state = filter[0]['province_name'];

          //           cityVal.value = "";
          //           payment.billing.city = "";
          //           zipCode.value = [];
          //           payment.billing.zipcode = "";
          //      } else {
          //           cityVal.value = "";
          //           payment.billing.state = "";
          //           payment.billing.city = "";
          //           zipCode.value = [];
          //           payment.billing.zipcode = "";
          //           city.value = [];
          //      }
          // });

          // watch(cityVal, async (newVal) => {
          //      if (newVal) {
          //           if (stateVal.value != null) {
          //                getZipCode(stateVal.value, newVal);
          //                payment.billing.city = newVal;
          //                payment.billing.zipcode = "";
          //           }
          //      } else {
          //           if (stateVal.value != null) {
          //                payment.billing.zipcode = "";
          //                payment.billing.city = "";
          //                zipCode.value = [];
          //           }
          //      }
          // });

          watch(isAddNewCard, async (newVal) => {
               if (newVal) {
                    loadingCard.value = true;

                    await new Promise((resolve) => setTimeout(resolve, 500));

                    var configure = {
                         paypageId:
                              eprotectUrl[worldpayStatus.value]["paypageId"],
                         height: "272px",
                         width: "100%",
                         style: eprotectUrl[worldpayStatus.value]["style"],
                         reportGroup: document.getElementById(
                              "request$reportGroup"
                         ).value,
                         timeout: document.getElementById("request$timeout")
                              .value,
                         div: "payframe",
                         callback: payframeClientCallback,
                         showCvv: true,
                         noScrollBar: true,
                         months: {
                              1: "January",
                              2: "February",
                              3: "March",
                              4: "April",
                              5: "May",
                              6: "June",
                              7: "July",
                              8: "August",
                              9: "September",
                              10: "October",
                              11: "November",
                              12: "December"
                         },
                         numYears: 8,
                         tooltipText:
                              "A CVV is the 3 digit code on the back of your Visa, MasterCard and Discover or a 4 digit code on the front of your American Express",
                         tabIndex: {
                              accountNumber: 1,
                              cvv: 2,
                              expMonth: 3,
                              expYear: 4
                         },
                         placeholderText: {
                              cvv: "CVV",
                              accountNumber: "Account Number"
                         },
                         htmlTimeout:
                              document.getElementById("request$timeout").value,
                         inputsEmptyCallback: inputsEmptyCallback,
                         clearCvvMaskOnReturn: false,
                         enhancedUxFeatures: {
                              inlineFieldValidations: true,
                              numericInputsOnly: false,
                              enhancedUxVersion: 2
                         }
                    };
                    var payframeClient = await new EprotectIframeClient(
                         configure
                    );
                    payframeClientLoaded.value = payframeClient;

                    loadingCard.value = false;
               }
          });

          watch(cardDetails, (newVal) => {
               if (newVal) {
                    selectedCard.value = newVal[0];
               }
          });

          watch(wp_response, (newVal) => {
               if (newVal) {
                    wp_response.value = newVal;
               }
          });

          return {
               // state,
               // city,
               // zipCode,
               money,
               // stateVal,
               // cityVal,
               payment,
               selectedCard,
               numbersOnly,
               formDisable,
               onSubmit,
               errorCard,
               clearFormError,
               openDeleteCC,
               deleteCardID,
               showCVV,
               cardError,
               isAddNewCard,
               addNewCard,
               openConfirmationModal,
               setSelectedCard,
               cancelButton,
               submitPurchaseCoinApi,
               cardDetails,
               purchase_loading,
               wp_response,
               baseUrl,
               checkActionRestricted,
               loadingCard,
               initRecaptcha
          };
     }
};
</script>

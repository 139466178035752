<template>
     <Slider
          v-if="type == 'slider'"
          :data="games"
          :title="active.replaceAll('-', ' ')"
          :onNextSlide="onSeeMore"
          :pagination="pagination"
          :active="active"
     >
          <template v-slot:icon>
               <span
                    class="text-2xl"
                    :class="'icon-' + active"
               ></span>
          </template>
     </Slider>
     <Paginate
          v-else-if="type == 'paginate'"
          :data="games"
          :title="active.replaceAll('-', ' ')"
          :onSeeMore="onSeeMore"
          :pagination="pagination"
          :active="active"
     >
          <template v-slot:icon>
               <span
                    class="text-2xl"
                    :class="'icon-' + active"
               ></span>
          </template>
     </Paginate>
</template>
<script>
import { computed, reactive, watch } from "vue";
import { gamesStore } from "store/games";
import Slider from "components/games/slider/index.vue";
import Paginate from "components/games/paginate/index.vue";

export default {
     components: {
          Slider,
          Paginate
     },
     props: ["active", "type"],
     setup(props) {
          const useGamesStore = gamesStore();

          const games = reactive({
               data: null
          });

          const pagination = reactive({
               current: null,
               last: null,
               next: null
          });

          const gamesData = computed(() => {
               if (useGamesStore.allGames[props.active]) {
                    games.data = useGamesStore.allGames[props.active]["data"];
                    pagination["current"] =
                         useGamesStore.allGames[props.active]["current_page"];
                    pagination["last"] =
                         useGamesStore.allGames[props.active]["last_page"];
                    const temp =
                         useGamesStore.allGames[props.active]["next_page_url"];
                    pagination["next"] =
                         temp != null ? temp.slice(-1) : pagination["last"];
               }
               return useGamesStore.allGames[props.active] || null;
          });

          const params = reactive({});

          const gameFilter = computed(() => {
               const obj = Object.keys(useGamesStore.gameFilter);
               for (let index = 0; index < obj.length; index++) {
                    params[obj[index]] = useGamesStore.gameFilter[obj[index]];
               }

               return useGamesStore.gameFilter;
          });

          const onSeeMore = async () => {
               const data = {
                    game_name: "",
                    game_promo: 0,
                    game_type: [props.active],
                    provider_id: params.provider_id,
                    page: pagination["next"],
                    itemsPerPage: 21,
                    sortType: params.sortType
               };

               await useGamesStore.getGames(data, props.active, false, true);
          };

          watch(gamesData, (newVal) => {
               if (newVal[props.active]) {
                    games.data = newVal[props.active]["data"];
                    pagination["current"] =
                         newVal[props.active]["current_page"];
                    pagination["last"] = newVal[props.active]["last_page"];
                    const temp = newVal[props.active]["next_page_url"];
                    pagination["next"] =
                         temp != null ? temp.slice(-1) : pagination["last"];
               }
          });

          watch(gameFilter, () => {});

          return {
               games,
               onSeeMore,
               pagination
          };
     }
};
</script>

import request from "@/utils/request";

export function paymentDetails() {
     return request({
          url: "/transaction/card_list",
          method: "get"
     });
}

export function getCreditCard() {
     return request({
          url: "/transaction/withdraw/card_list",
          method: "get"
     });
}

export function cardValidation(data) {
     return request({
          url: "/transaction/purchase/validate_credit_card",
          method: "post",
          data: data
     });
}

export function purchaseCoin(data) {
     return request({
          url: "/transaction/purchase/coin",
          method: "post",
          data: data
     });
}

export function saveCard(data) {
     return request({
          url: "/transaction/card_link_action",
          method: "post",
          data: data
     });
}

<template>
     <InertiaHead title="Redeem"></InertiaHead>
     <div
          class="moozi-min-h w-full h-full relative flex justify-center bg-moozi-1"
     >
          <div class="w-full 2xl:max-w-screen-2xl bg-transparent relative">
               <div
                    class="w-full h-full flex flex-col justify-center items-center px-5 xs:px-8 sm:px-10 md:px-24 lg:px-28 xl:px-32 pb-36 sm:pb-44 md:pb-20 lg:pb-28 xl:pb-32 pt-16 sm:pt-20 lg:pt-28 xl:pt-32"
               >
                    <h1
                         class="text-white font-helvetica tracking-wide text-center text-5xl xs:text-5xl sm:text-5xl md:text-6xl xl:text-8xl leading-5xl xs:leading-5xl sm:leading-6xl md:leading-6xl xl:leading-7.5xl"
                    >
                         REDEEM
                    </h1>
                    <div
                         class="relative bg-gradient-promo-detail rounded-xl flex flex-row w-full mt-6 sm:mt-8 md:mt-12 lg:mt-18"
                    >
                         <div
                              class="w-full lg:w-4/12 xl:w-3/12 absolute z-20 lg:static lg:z-0"
                         >
                              <div
                                   class="flex flex-col justify-between items-center w-full h-full"
                              >
                                   <div
                                        class="w-full lg:pl-5 xl:pl-10 md:pt-0 lg:pt-5"
                                   >
                                        <ul
                                             class="overflow-x-auto lg:overflow-x-visible game-custom-scrollbar space-y-1"
                                        >
                                             <li
                                                  :class="
                                                       openMenu
                                                            ? 'transparent block'
                                                            : 'hidden'
                                                  "
                                                  class="text-white font-roboto font-medium tracking-wider w-full !mt-0"
                                                  @click="openMenu = !openMenu"
                                             >
                                                  <span
                                                       class="account-details-list flex justify-between items-center px-2 py-3 lg:py-3 rounded-lg w-full cursor-pointer lg:justify-start"
                                                       aria-current="page"
                                                  >
                                                       <div
                                                            class="w-4 ml-3"
                                                       ></div>
                                                       <span
                                                            class="text-sm sm:text-base md:text-lg lg:text-sm xl:text-base"
                                                            >My Account</span
                                                       >
                                                       <svg
                                                            :class="
                                                                 openMenu
                                                                      ? 'rotate-180'
                                                                      : ''
                                                            "
                                                            class="transition-transform duration-300 mr-3"
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 20 20"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                       >
                                                            <path
                                                                 fill-rule="evenodd"
                                                                 clip-rule="evenodd"
                                                                 d="M5.29279 7.29259C5.48031 7.10512 5.73462 6.99981 5.99979 6.99981C6.26495 6.99981 6.51926 7.10512 6.70679 7.29259L9.99979 10.5856L13.2928 7.29259C13.385 7.19708 13.4954 7.1209 13.6174 7.06849C13.7394 7.01608 13.8706 6.9885 14.0034 6.98734C14.1362 6.98619 14.2678 7.01149 14.3907 7.06177C14.5136 7.11205 14.6253 7.18631 14.7192 7.2802C14.8131 7.37409 14.8873 7.48574 14.9376 7.60864C14.9879 7.73154 15.0132 7.86321 15.012 7.99599C15.0109 8.12877 14.9833 8.25999 14.9309 8.382C14.8785 8.504 14.8023 8.61435 14.7068 8.70659L10.7068 12.7066C10.5193 12.8941 10.265 12.9994 9.99979 12.9994C9.73462 12.9994 9.48031 12.8941 9.29279 12.7066L5.29279 8.70659C5.10532 8.51907 5 8.26476 5 7.99959C5 7.73443 5.10532 7.48012 5.29279 7.29259Z"
                                                                 fill="white"
                                                            />
                                                       </svg>
                                                  </span>
                                             </li>

                                             <li
                                                  v-for="(
                                                       items, key
                                                  ) in sidebarTabs"
                                                  :key="key"
                                                  :class="
                                                       openMenu
                                                            ? 'bg-shade-6 lg:transparent'
                                                            : ''
                                                  "
                                                  class="text-white font-roboto font-medium tracking-wider w-full !mt-0"
                                             >
                                                  <span
                                                       @click="
                                                            () =>
                                                                 setSidebarTabs(
                                                                      items.type
                                                                 )
                                                       "
                                                       :class="
                                                            activeSidebarTab ==
                                                            items.type
                                                                 ? openMenu
                                                                      ? 'text-shade-8 md:flex'
                                                                      : 'flex lg:text-secondary-2'
                                                                 : openMenu
                                                                   ? 'text-shade-8 block'
                                                                   : 'text-white hidden lg:flex'
                                                       "
                                                       class="account-details-list flex items-center px-2 py-3 lg:py-3 rounded-lg w-full cursor-pointer justify-between lg:justify-start"
                                                       aria-current="page"
                                                  >
                                                       <span
                                                            v-if="
                                                                 items.type ==
                                                                 'redeem'
                                                            "
                                                            :class="
                                                                 items.type ==
                                                                 activeSidebarTab
                                                                      ? 'active'
                                                                      : ''
                                                            "
                                                            class="icon-redeem text-2xl me-3 xl:me-5 hidden lg:block"
                                                       ></span>
                                                       <span
                                                            v-if="
                                                                 items.type ==
                                                                 'redeem-history'
                                                            "
                                                            :class="
                                                                 items.type ==
                                                                 activeSidebarTab
                                                                      ? 'active'
                                                                      : ''
                                                            "
                                                            class="icon-redeem text-2xl me-3 xl:me-5 hidden lg:block"
                                                       ></span>
                                                       <div
                                                            class="w-4 ml-3 lg:hidden"
                                                       ></div>
                                                       <span
                                                            class="text-sm sm:text-base md:text-lg lg:text-sm xl:text-base"
                                                            >{{
                                                                 items.label
                                                            }}</span
                                                       >
                                                       <svg
                                                            :class="
                                                                 openMenu
                                                                      ? 'rotate-180'
                                                                      : ''
                                                            "
                                                            class="transition-transform duration-300 mr-3 lg:hidden"
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 20 20"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                       >
                                                            <path
                                                                 fill-rule="evenodd"
                                                                 clip-rule="evenodd"
                                                                 d="M5.29279 7.29259C5.48031 7.10512 5.73462 6.99981 5.99979 6.99981C6.26495 6.99981 6.51926 7.10512 6.70679 7.29259L9.99979 10.5856L13.2928 7.29259C13.385 7.19708 13.4954 7.1209 13.6174 7.06849C13.7394 7.01608 13.8706 6.9885 14.0034 6.98734C14.1362 6.98619 14.2678 7.01149 14.3907 7.06177C14.5136 7.11205 14.6253 7.18631 14.7192 7.2802C14.8131 7.37409 14.8873 7.48574 14.9376 7.60864C14.9879 7.73154 15.0132 7.86321 15.012 7.99599C15.0109 8.12877 14.9833 8.25999 14.9309 8.382C14.8785 8.504 14.8023 8.61435 14.7068 8.70659L10.7068 12.7066C10.5193 12.8941 10.265 12.9994 9.99979 12.9994C9.73462 12.9994 9.48031 12.8941 9.29279 12.7066L5.29279 8.70659C5.10532 8.51907 5 8.26476 5 7.99959C5 7.73443 5.10532 7.48012 5.29279 7.29259Z"
                                                                 fill="white"
                                                            />
                                                       </svg>
                                                  </span>
                                             </li>
                                        </ul>
                                   </div>
                                   <img
                                        :src="
                                             baseUrl.img +
                                             '/assets/img/cow-with-logo.png'
                                        "
                                        alt="cow"
                                        class="mt-8 md:mt-16 mb-8 hidden lg:block"
                                   />
                              </div>
                         </div>
                         <!-- <div class="w-6/12 hidden lg:block">
                              <div class="flex flex-col justify-between items-center w-full h-full">
                                   <div class="flex gap-5 items-center w-full mt-16 ml-60">
                                        <span class="icon-redeem text-2xl"></span>
                                        <p class="text-white font-roboto font-extrabold tracking-wider text-sm sm:text-lg md:text-xl lg:text-lg xl:text-lg">
                                             Redeem
                                        </p>
                                   </div>
                                   <img
                                        :src="baseUrl.img+'/assets/img/cow-with-logo.png'"
                                        alt="cow"
                                        class=" mt-8 md:mt-16 mb-8"
                                   >
                              </div>
                         </div> -->
                         <RedeemHistory
                              v-if="activeSidebarTab == 'redeem-history'"
                              :transaction_data="transaction_data"
                              :transaction_data_loading="
                                   transaction_data_loading
                              "
                              :goToTransaction="goToTransaction"
                         />
                         <div
                              v-if="activeSidebarTab == 'redeem'"
                              class="mt-12 lg:mt-0 bg-white lg:w-8/12 xl:w-9/12 px-5 md:px-7 lg:px-10 2xl:px-36 py-3 md:py-5 lg:py-10 flex flex-col justify-center items-center rounded-xl rounded-t-none lg:rounded-l-none lg:rounded-r-xl"
                         >
                              <p
                                   class="text-shade-11 font-helveticaLight tracking-wide mb-4 md:mb-8 text-start font-normal text-sm sm:text-lg md:text-xl lg:text-sm xl:text-base"
                              >
                                   SCs Coins can only be redeemed for cash after
                                   you have used them to play and is subject to
                                   a minimum of 100 played SCs Coins.
                              </p>
                              <div
                                   class="flex flex-row justify-around items-center w-full mb-8 md:mb-14"
                              >
                                   <div
                                        class="flex flex-col justify-center items-center"
                                   >
                                        <p
                                             class="text-shade-11 font-helveticaLight tracking-wide text-start lg:text-center md:text-start font-normal text-sm sm:text-lg md:text-xl lg:text-sm xl:text-base"
                                        >
                                             Coins balance
                                        </p>
                                        <div
                                             class="flex flex-row items-center gap-2 mt-4"
                                        >
                                             <img
                                                  class="cursor-pointer w-6 h-6"
                                                  :src="
                                                       baseUrl.img +
                                                       '/assets/img/header/SC.svg'
                                                  "
                                                  alt=""
                                             />
                                             <p
                                                  class="text-black font-helveticaLight font-bold tracking-wide text-center md: text-sm sm:text-lg md:text-xl lg:text-lg xl:text-lg"
                                             ></p>
                                             <p
                                                  class="text-black font-helveticaLight font-bold tracking-wide text-center md: text-sm sm:text-lg md:text-xl lg:text-lg xl:text-lg"
                                             >
                                                  {{ money(silver_balance) }} SC
                                             </p>
                                        </div>
                                   </div>
                                   <div
                                        class="flex flex-col justify-center items-center"
                                   >
                                        <p
                                             class="text-shade-11 font-helveticaLight tracking-wide text-start lg:text-center md:text-start font-normal text-sm sm:text-lg md:text-xl lg:text-sm xl:text-base"
                                        >
                                             Available to redeem
                                        </p>
                                        <div
                                             class="flex flex-row items-center gap-2 mt-4"
                                        >
                                             <img
                                                  class="cursor-pointer w-6 h-6"
                                                  :src="
                                                       baseUrl.img +
                                                       '/assets/img/header/SC.svg'
                                                  "
                                                  alt=""
                                             />
                                             <p
                                                  class="text-black font-helveticaLight font-bold tracking-wide text-center md: text-sm sm:text-lg md:text-xl lg:text-lg xl:text-lg"
                                             ></p>
                                             <p
                                                  class="text-black font-helveticaLight font-bold tracking-wide text-center md: text-sm sm:text-lg md:text-xl lg:text-lg xl:text-lg"
                                             >
                                                  <!-- {{ silver_balance }}
                                                {{redeemable}}
                                                {{silver_balance <= redeemable}} -->
                                                  {{
                                                       money(
                                                            silver_balance_redeem
                                                       )
                                                  }}
                                                  SC
                                             </p>
                                        </div>
                                   </div>
                              </div>
                              <div class="flex justify-start w-full">
                                   <div class="flex flex-row gap-2">
                                        <div
                                             v-if="
                                                  isActionAccessible(
                                                       'wd_card_button'
                                                  )
                                             "
                                             @click="tabs = 'credit'"
                                             class="hover:bg-background-3 hover:text-white font-helveticaLight tracking-wide rounded-full px-8 py-2 cursor-pointer transition-colors duration-300"
                                             :class="
                                                  tabs == 'credit'
                                                       ? 'bg-background-3 text-white'
                                                       : 'bg-white text-background-3 font-bold'
                                             "
                                        >
                                             Credit Card
                                        </div>
                                        <div
                                             v-if="
                                                  isActionAccessible(
                                                       'wd_bank_button'
                                                  )
                                             "
                                             @click="tabs = 'wallet'"
                                             class="hover:bg-background-3 hover:text-white font-helveticaLight tracking-wide rounded-full px-8 py-2 cursor-pointer transition-colors duration-300"
                                             :class="
                                                  tabs == 'wallet'
                                                       ? 'bg-background-3 text-white'
                                                       : 'bg-white text-background-3 font-bold'
                                             "
                                        >
                                             Bank Transfer
                                        </div>
                                   </div>
                              </div>
                              <div
                                   class="flex items-center mb-2 w-full font-bold mt-5 text-sm sm:text-base md:text-lg lg:text-base xl:text-lg"
                              >
                                   {{
                                        tabs == "credit"
                                             ? "Credit Card List"
                                             : "Bank Transfer List"
                                   }}
                              </div>
                              <CreditCard
                                   v-if="
                                        tabs == 'credit' &&
                                        isActionAccessible('wd_card_button')
                                   "
                                   :form="form"
                                   :onUpdateID="
                                        (val, data) => {
                                             form.accountId = val;
                                             card_selected = data;
                                        }
                                   "
                                   :clearFormError="clearFormError"
                                   :card_selected="card_selected"
                                   :hideForm="true"
                                   :updateForm="
                                        (key, value) => {
                                             updateForm(key, value);
                                        }
                                   "
                              />
                              <Wallet
                                   v-if="
                                        tabs == 'wallet' &&
                                        isActionAccessible('wd_bank_button')
                                   "
                                   :form="form"
                                   :onUpdateID="
                                        (val) => {
                                             form.accountId = val;
                                        }
                                   "
                                   :setLoading="setLoading"
                              ></Wallet>
                              <div
                                   class="border border-solid border-shade-6 mt-2 mb-4 w-full"
                              ></div>
                              <p
                                   class="text-shade-11 font-helveticaLight font-bold tracking-wide text-start sm:text-lg md:text-xl lg:text-lg xl:text-lg w-full mb-2 mt-3"
                              >
                                   Redeem amount
                              </p>
                              <p
                                   class="text-shade-11 font-helveticaLight tracking-wide text-start w-full font-normal text-sm sm:text-lg md:text-xl lg:text-sm xl:text-base"
                              >
                                   Input the amount you want to redeem. Minimum
                                   100 SC coins.
                              </p>
                              <transition
                                   enter-from-class="opacity-0"
                                   enter-active-class="transition ease-in duration-300"
                                   enter-to-class="opacity-100 scale-100"
                              >
                                   <div
                                        v-if="checkActionRestricted()"
                                        class="rounded-xl !mt-0 bg-primal-yellow text-shade-19 flex flex-row items-center gap-2 ease-in-out transition-all duration-300 p-3 mb-4 w-full"
                                   >
                                        <svg
                                             class="w-5 h-5"
                                             viewBox="0 0 20 20"
                                             fill="none"
                                             xmlns="http://www.w3.org/2000/svg"
                                        >
                                             <path
                                                  d="M10 7V11M10 14V14.01M1 10C1 11.1819 1.23279 12.3522 1.68508 13.4442C2.13738 14.5361 2.80031 15.5282 3.63604 16.364C4.47177 17.1997 5.46392 17.8626 6.55585 18.3149C7.64778 18.7672 8.8181 19 10 19C11.1819 19 12.3522 18.7672 13.4442 18.3149C14.5361 17.8626 15.5282 17.1997 16.364 16.364C17.1997 15.5282 17.8626 14.5361 18.3149 13.4442C18.7672 12.3522 19 11.1819 19 10C19 8.8181 18.7672 7.64778 18.3149 6.55585C17.8626 5.46392 17.1997 4.47177 16.364 3.63604C15.5282 2.80031 14.5361 2.13738 13.4442 1.68508C12.3522 1.23279 11.1819 1 10 1C8.8181 1 7.64778 1.23279 6.55585 1.68508C5.46392 2.13738 4.47177 2.80031 3.63604 3.63604C2.80031 4.47177 2.13738 5.46392 1.68508 6.55585C1.23279 7.64778 1 8.8181 1 10Z"
                                                  stroke="#FFF2B1"
                                                  stroke-width="2"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                             />
                                        </svg>
                                        <p
                                             class="text-sm lg:text-base font-medium"
                                        >
                                             This action is currently restricted
                                             pls contact support
                                        </p>
                                   </div>
                              </transition>
                              <input
                                   v-model="form.amount"
                                   type="text"
                                   name="amount"
                                   id="amount"
                                   :readonly="
                                        tabs == 'credit'
                                             ? !isActionAccessible(
                                                    'wd_card_button'
                                               )
                                             : !isActionAccessible(
                                                    'wd_bank_button'
                                               )
                                   "
                                   class="bg-shade-6 border border-shade-12 font-helveticaLight tracking-wide text-lg rounded-lg focus:!ring-background-2 focus:!border-background-2 block w-full p-2.5 focus:bg-blue-10 mt-3"
                                   @change="clearFormError('amount')"
                                   :class="[
                                        form.errors?.errors?.amount != null
                                             ? '!border-primal-red focus:!ring-primal-red focus:!border-primal-red'
                                             : '!border-background-3 focus:!ring-background-2 focus:!border-background-2',
                                        form.amount != ''
                                             ? 'text-shade-2'
                                             : 'text-shade-13'
                                   ]"
                              />
                              <p
                                   class="w-full font-helveticaLight tracking-wide text-sm text-red-10 mt-1"
                              >
                                   {{ form.errors?.errors?.amount }}
                              </p>
                              <vue-recaptcha
                                   v-if="initRecaptcha"
                                   :sitekey="VITE_RECAPTCHA_SITE_KEY"
                                   @verify="verifyMethod"
                                   @expired="expiredMethod"
                                   @render="renderMethod"
                                   @error="errorMethod"
                                   class="my-5 w-full flex items-center justify-center"
                              ></vue-recaptcha>
                              <div class="flex justify-end w-full mt-10 mb-10">
                                   <div
                                        :class="
                                             tokenResponse != null &&
                                             form.amount != '' &&
                                             form.accountId != '' &&
                                             form.amount >= 100 &&
                                             parseInt(form.amount) <=
                                                  parseInt(redeemable) &&
                                             !checkActionRestricted()
                                                  ? 'bg-secondary-3 text-primary-1 cursor-pointer'
                                                  : 'bg-gray-190 text-shade-7 cursor-not-allowed'
                                        "
                                        class="rounded-full font-roboto font-bold tracking-wider px-10 py-3 text-sm sm:text-base md:text-lg lg:text-base xl:text-lg"
                                        :style="
                                             tokenResponse != null &&
                                             form.amount != '' &&
                                             form.accountId != '' &&
                                             form.amount >= 100 &&
                                             parseInt(form.amount) <=
                                                  parseInt(redeemable) &&
                                             !checkActionRestricted()
                                                  ? ''
                                                  : 'box-shadow: 0px 4px 5.7px -2px rgba(0, 0, 0, 0.15) inset;'
                                        "
                                        @click="
                                             () => {
                                                  if (
                                                       tokenResponse != null &&
                                                       form.amount != '' &&
                                                       form.accountId != '' &&
                                                       form.amount >= 100 &&
                                                       parseInt(form.amount) <=
                                                            parseInt(
                                                                 redeemable
                                                            ) &&
                                                       !checkActionRestricted()
                                                  ) {
                                                       onSubmit();
                                                  }
                                             }
                                        "
                                   >
                                        Submit
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
          <WithdrawReminder
               :tabs="tabs"
               :onWithdrawCredit="() => withdrawSCCredit()"
               :onWithdrawWallet="() => withdrawSCWallet()"
          />
          <loading-overlay :isLoading="loading" />
          <AddBankPayment />
          <BankWallet />
     </div>
</template>
<script>
import { reactive, ref, onMounted, computed, watch } from "vue";
import { Head as InertiaHead } from "@inertiajs/vue3";
import _ from "underscore";
import { useFormatter } from "composables/use-formatter.js";
import { useRouter } from "vue-router";
import { coinsStore } from "store/coins";
import { authStore } from "store/auth.js";
import Form from "vform";
import {
     make_withdraw as makeWithdrawAPI,
     make_withdraw_cc as makeWithdrawccAPI,
     transaction_history as transactionHistoryAPi,
     limitTransaction as limitTransactionAPI
} from "api/transaction.js";
import LoadingOverlay from "components/loading/loadingOverlay.vue";
import { useToaster } from "composables/use-toaster.js";
import { useScroll } from "composables/use-scroll.js";
import WithdrawReminder from "components/modals/withdrawReminder.vue";
import eventBus from "plugins/event.js";
import CreditCard from "./component/credit.vue";
import Wallet from "./component/wallet.vue";
import { utilitiesStore } from "store/utilities.js";
import { baseUrl } from "config/services.js";
import { useActionRestriction } from "composables/use-restriction.js";
import { useBank } from "composables/use-bank.js";
import { cardStore } from "store/cards.js";
const VITE_RECAPTCHA_SITE_KEY = import.meta.env.VITE_RECAPTCHA_SITE_KEY;
import { VueRecaptcha } from "vue-recaptcha";
import RedeemHistory from "./component/tabs/redeem-history.vue";
import AddBankPayment from "components/modals/addBankPayment/index.vue";
import BankWallet from "components/modals/bankWallet.vue";
import { eprotectUrl } from "config/services.js";

export default {
     components: {
          InertiaHead,
          LoadingOverlay,
          WithdrawReminder,
          CreditCard,
          Wallet,
          VueRecaptcha,
          RedeemHistory,
          AddBankPayment,
          BankWallet
     },
     setup() {
          const { isActionAccessible } = useBank();
          const { isActionRestricted } = useActionRestriction();

          const { date6, money } = useFormatter();
          const router = useRouter();
          const { scrollTop } = useScroll();

          const loading = ref(false);
          const { infoToast, successToast, errorToast } = useToaster();

          const useCoinsStore = coinsStore();
          const useAuthStore = authStore();
          const useUtilitiesStore = utilitiesStore();
          const useCardStore = cardStore();

          const openMenu = ref(false);

          const activeSidebarTab = ref("redeem");

          const sidebarTabs = ref([
               { label: "Redeem", type: "redeem" },
               { label: "Redeem History", type: "redeem-history" }
          ]);

          const transaction_data = ref([]);
          const transaction_data_loading = ref(false);
          const transaction_limit = ref(null);

          const card_selected = ref(null);

          const tokenResponse = ref(null);
          const initRecaptcha = ref(false);

          const tabs = ref("wallet");

          const redeemable = computed(() => {
               return useCoinsStore.redeemable;
          });

          const bankStatus = computed(() => {
               if (useCardStore.bankStatus) {
                    setActiveTab(useCardStore.bankStatus);
               }
               return useCardStore.bankStatus;
          });

          const isUser = computed(() => {
               return useAuthStore.user;
          });

          const form = reactive(
               new Form({
                    amount: "",
                    accountId: "",
                    type: "wallet",
                    billing: {
                         address_1: "",
                         address_2: "",
                         state: "",
                         city: "",
                         zipCode: ""
                    },
                    card_number: "",
                    card_expiration: ""
               })
          );

          const coins = computed(() => {
               return useCoinsStore.coins || null;
          });

          const silver_balance = computed(() => {
               return (
                    parseFloat(useAuthStore.silver_balance) +
                    parseFloat(useAuthStore.silver_balance_redeem)
               );
          });

          const silver_balance_redeem = computed(() => {
               return parseFloat(useAuthStore.silver_balance_redeem);
          });

          const filterCoins = (val) => {
               return _.filter(coins.value.coins, function (params) {
                    return params["type"] == val;
               });
          };

          const worldpayStatus = computed(() => {
               return useCoinsStore.worldpayStatus == true ? "prod" : "dev";
          });

          const goTo = (id) => {
               router.push({ path: "/payment/" + id });
          };

          const goToTransaction = () => {
               router.push({
                    path: "/account/",
                    query: { tab: "coins", filter: "withdraw" }
               });
          };

          const goToWallet = () => {
               router.push({
                    path: "/account/",
                    query: { tab: "payment-details", panel: "wallet" }
               });
          };

          const onSubmit = async () => {
               if (form.accountId == "") {
                    errorToast("Please Select Wallet!", "top-right");
                    return;
               }

               const temp = tabs.value == "credit" ? "worldpay" : "fiserv";

               const filter = _.filter(
                    transaction_limit.value,
                    function (params) {
                         return params["provider"].toLowerCase() == temp;
                    }
               );

               // console.log(parseInt(filter[0]['wd_max_transaction']));
               // console.log(form.amount);
               // console.log(parseInt(form.amount) >= parseInt(filter[0]['wd_max_transaction']));

               if (
                    parseInt(form.amount) >=
                    parseInt(filter[0]["wd_max_transaction"])
               ) {
                    openWidthdrawReminder();
               } else {
                    if (tabs.value == "credit") {
                         await withdrawSCCredit();
                    } else {
                         await withdrawSCWallet();
                    }
               }
          };

          const withdrawSCWallet = async (isPending = false) => {
               const params = {};
               params["accountId"] = form.accountId;
               params["amount"] = form.amount;
               params["amount"] = form.amount;
               params["g-recaptcha-response"] = tokenResponse.value;

               loading.value = true;
               await makeWithdrawAPI(params)
                    .then(async (res) => {
                         if (res) {
                              await withdrawTransaction();
                              await useCoinsStore.balanceRedeemable();

                              closeWidthdrawReminder();
                              if (isPending) {
                                   infoToast(
                                        "Please wait for a moment! you will receive your money in few moments",
                                        "top-right"
                                   );
                              } else {
                                   successToast(
                                        "Redeem Successful",
                                        "top-right"
                                   );
                              }
                              delete form.errors.errors["amount"];
                              form.amount = "";
                              loading.value = false;

                              initRecaptcha.value = false;
                              setTimeout(() => {
                                   initRecaptcha.value = true;
                              }, 1000);
                         }
                    })
                    .catch(() => {
                         closeWidthdrawReminder();
                         loading.value = false;
                         initRecaptcha.value = false;
                         errorToast("Redeem Unsuccessful", "top-right");
                         setTimeout(() => {
                              initRecaptcha.value = true;
                         }, 1000);
                    });
          };

          const withdrawSCCredit = async (isPending = false) => {
               loading.value = true;

               setTimeout(async () => {
                    // const tempState = form.billing.state;
                    // const filterState = _.filter(state.value, function (params) {
                    //      return params["province_name"] == tempState;
                    // })
                    const params = {
                         amount: form.amount,
                         card_id: form.accountId,
                         "g-recaptcha-response": tokenResponse.value
                         // billing: {
                         //      address_1: form.billing.address_1,
                         //      state: filterState[0]["province_name_abbr"],
                         //      city: form.billing.city,
                         //      zipcode: form.billing.zipCode,
                         // },
                         // card_number: form.card_number,
                         // card_type: card_selected?.value.paypage?.type,
                         // card_expiration: card_selected?.value.paypage?.card_expiration != null ? card_selected?.value.paypage?.card_expiration : form.card_expiration,
                         // payment_category: 'CARD'
                    };

                    await makeWithdrawccAPI(params)
                         .then(async (res) => {
                              if (res) {
                                   await withdrawTransaction();
                                   await useCoinsStore.balanceRedeemable();
                                   closeWidthdrawReminder();

                                   if (isPending) {
                                        infoToast(
                                             "Please wait for a moment! you will receive your money in few moments",
                                             "top-right"
                                        );
                                   } else {
                                        successToast(
                                             "Redeem Successful",
                                             "top-right"
                                        );
                                   }
                                   delete form.errors.errors["amount"];
                                   form.amount = "";
                                   loading.value = false;
                                   card_selected.value = null;

                                   initRecaptcha.value = false;
                                   setTimeout(() => {
                                        initRecaptcha.value = true;
                                   }, 1000);
                              }
                         })
                         .catch(() => {
                              closeWidthdrawReminder();
                              loading.value = false;
                              initRecaptcha.value = false;
                              errorToast("Redeem Unsuccessful", "top-right");
                              setTimeout(() => {
                                   initRecaptcha.value = true;
                              }, 1000);
                         });
               }, 500);
          };

          const clearFormError = (key) => {
               if (
                    form.amount < 100 ||
                    form.amount > parseInt(redeemable.value)
               ) {
                    form.errors.errors[key] = "invalid amount";
               } else {
                    if (typeof form.errors.errors[key] !== "undefined") {
                         delete form.errors.errors[key];
                    }
               }
          };

          const transactionLimit = async () => {
               await limitTransactionAPI()
                    .then((res) => {
                         if (res) {
                              transaction_limit.value = res;
                         }
                    })
                    .catch((err) => {
                         console.error(err);
                    });
          };

          const withdrawTransaction = async () => {
               transaction_data_loading.value = true;
               await transactionHistoryAPi({
                    page: 1,
                    itemsPerPage: 10,
                    transaction_type: "withdraw"
               }).then((data) => {
                    transaction_data.value = data.data;
               });
               transaction_data_loading.value = false;
          };

          const openWidthdrawReminder = () => {
               eventBus.emit("open:withdraw_reminder");
          };

          const closeWidthdrawReminder = () => {
               eventBus.emit("close:withdraw_reminder");
          };

          const updateForm = (key, value) => {
               const split = key.split(".");
               if (split.length == 1) {
                    form[key] = value;
               } else if (split.length == 2) {
                    form[split[0]][split[1]] = value;
               }
          };

          const checkActionRestricted = () => {
               // WITHDRAW
               const restrictedActionType = 2;

               return isActionRestricted(restrictedActionType);
          };

          const verifyMethod = async (response) => {
               tokenResponse.value = response;
          };

          const expiredMethod = async () => {
               tokenResponse.value = null;
               console.error("expired");
          };

          const renderMethod = async () => {
               // console.log(id);
          };

          const errorMethod = async () => {
               tokenResponse.value = null;
               console.error("error");
          };

          const setSidebarTabs = async (tab) => {
               activeSidebarTab.value = tab;

               if (window.outerWidth < 1024) {
                    openMenu.value = !openMenu.value;
               }

               // if (tab == "account-details") {
               //      resetProfileForm();
               // }

               // if (tab == "coins") {
               //      if(fromQueryFilter.value == true) {
               //           filter.value = null;
               //           filterLabel.value = null;
               //           fromQueryFilter.value = false

               //           const params = {
               //                page: 1,
               //                itemsPerPage: 10,
               //           };

               //           params['transaction_type'] = '';

               //           await getTransactionHistory(params);
               //      }
               // }

               // router.replace({ query: { tab: tab } });
          };

          const setLoading = (bool) => {
               loading.value = bool;
          };

          const setActiveTab = (newVal) => {
               const filter = _.filter(newVal, function (params) {
                    return ["wd_card_button", "wd_bank_button"].includes(
                         params["slug"]
                    );
               });

               if (filter?.length != 0) {
                    const bank = _.filter(newVal, function (params) {
                         return params["slug"] == "wd_bank_button";
                    });

                    const card = _.filter(newVal, function (params) {
                         return params["slug"] == "wd_card_button";
                    });

                    if (card[0].note == 1) {
                         tabs.value = "credit";
                         return;
                    } else if (bank[0].note == 1) {
                         tabs.value = "wallet";
                         return;
                    }
                    return;
               }

               tabs.value = "credit";
          };

          onMounted(async () => {
               const isExist = document.querySelector("#fiserv-import");
               if (isExist == null) {
                    var script = document.createElement("script");
                    script.id = "fiserv-import";
                    script.src =
                         import.meta.env.VITE_ENV == "production"
                              ? "https://prod.api.firstdata.com/gateway/v2/connectpay/static/v1/js/PaymentSDK.js"
                              : "https://cat.api.firstdata.com/gateway/v2/connectpay/static/v1/js/PaymentSDK.js";
                    document.body.appendChild(script);
               }

               const isExist1 = document.querySelector(
                    "#eprotect-jquery-import"
               );
               const isExist2 = document.querySelector("#eprotect-import");

               if (isExist1 == null && isExist2 == null) {
                    var script1 = document.createElement("script");
                    script1.id = "eprotect-jquery-import";
                    script1.src =
                         eprotectUrl[worldpayStatus.value][
                              "eprotect-jquery-import"
                         ];
                    document.body.appendChild(script1);

                    var script2 = document.createElement("script");
                    script2.id = "eprotect-import";
                    // https://request.eprotect.vantivprelive.com/eProtect/js/eProtect-iframe-client4.min.js
                    script2.src =
                         eprotectUrl[worldpayStatus.value]["eprotect-import"];
                    document.body.appendChild(script2);
               }

               transaction_data_loading.value = true;
               scrollTop();
               initRecaptcha.value = true;
               await useCoinsStore.getWorldPay();
               await useCardStore.getBankStatus();
               await useCoinsStore.getCoins();
               await useCoinsStore.balanceRedeemable();
               await transactionLimit();
               await withdrawTransaction();
               await useUtilitiesStore.getState();
          });

          watch(tabs, (newVal) => {
               form.type = newVal;
               form.accountId = "";
               card_selected.value = null;
          });

          watch(bankStatus, (newVal) => {
               if (newVal) {
                    setActiveTab(newVal);
               }
          });

          return {
               coins,
               filterCoins,
               date6,
               money,
               goTo,
               silver_balance,
               silver_balance_redeem,
               form,
               onSubmit,
               transaction_data,
               redeemable,
               clearFormError,
               loading,
               goToTransaction,
               goToWallet,
               transaction_data_loading,
               tabs,
               updateForm,
               card_selected,
               baseUrl,
               checkActionRestricted,
               isActionAccessible,
               withdrawSCCredit,
               withdrawSCWallet,
               VITE_RECAPTCHA_SITE_KEY,
               verifyMethod,
               expiredMethod,
               renderMethod,
               errorMethod,
               tokenResponse,
               openMenu,
               setSidebarTabs,
               sidebarTabs,
               activeSidebarTab,
               isUser,
               setLoading,
               initRecaptcha
          };
     }
};
</script>

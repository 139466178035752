<template>
     <Slider
          v-if="type == 'slider'"
          :data="games"
          title="Recent Play"
          :onNextSlide="onSeeMore"
          :pagination="pagination"
          :active="'recent'"
     >
          <template v-slot:icon>
               <svg
                    class="w-8"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
               >
                    <path
                         d="M7.3374 7.3374L6.39111 6.39111C5.97085 5.97085 5.25 6.26865 5.25 6.86147V9.92578C5.25 10.2959 5.5478 10.5938 5.91797 10.5938H8.98228C9.57788 10.5938 9.87568 9.8729 9.45542 9.45264L8.59819 8.59541C9.56396 7.62964 10.8999 7.03125 12.375 7.03125C15.3252 7.03125 17.7188 9.4248 17.7188 12.375C17.7188 15.3252 15.3252 17.7188 12.375 17.7188C11.2395 17.7188 10.1874 17.3653 9.32183 16.7613C8.91826 16.4802 8.3644 16.5776 8.08052 16.9812C7.79663 17.3848 7.89683 17.9386 8.30039 18.2225C9.4582 19.0269 10.8637 19.5 12.375 19.5C16.3104 19.5 19.5 16.3104 19.5 12.375C19.5 8.43955 16.3104 5.25 12.375 5.25C10.4073 5.25 8.62603 6.04878 7.3374 7.3374ZM12.375 8.8125C12.0048 8.8125 11.707 9.1103 11.707 9.48047V12.375C11.707 12.5531 11.7766 12.7229 11.9019 12.8481L13.9058 14.8521C14.1674 15.1137 14.5904 15.1137 14.8493 14.8521C15.1081 14.5904 15.1109 14.1674 14.8493 13.9085L13.0402 12.0995V9.48047C13.0402 9.1103 12.7424 8.8125 12.3722 8.8125H12.375Z"
                         fill="white"
                    ></path>
               </svg>
          </template>
     </Slider>
     <Paginate
          v-else-if="type == 'paginate'"
          :data="games"
          title="Recent Play"
          :onSeeMore="onSeeMore"
          :pagination="pagination"
          active="recent"
     >
          <template v-slot:icon>
               <svg
                    class="w-8"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
               >
                    <path
                         d="M7.3374 7.3374L6.39111 6.39111C5.97085 5.97085 5.25 6.26865 5.25 6.86147V9.92578C5.25 10.2959 5.5478 10.5938 5.91797 10.5938H8.98228C9.57788 10.5938 9.87568 9.8729 9.45542 9.45264L8.59819 8.59541C9.56396 7.62964 10.8999 7.03125 12.375 7.03125C15.3252 7.03125 17.7188 9.4248 17.7188 12.375C17.7188 15.3252 15.3252 17.7188 12.375 17.7188C11.2395 17.7188 10.1874 17.3653 9.32183 16.7613C8.91826 16.4802 8.3644 16.5776 8.08052 16.9812C7.79663 17.3848 7.89683 17.9386 8.30039 18.2225C9.4582 19.0269 10.8637 19.5 12.375 19.5C16.3104 19.5 19.5 16.3104 19.5 12.375C19.5 8.43955 16.3104 5.25 12.375 5.25C10.4073 5.25 8.62603 6.04878 7.3374 7.3374ZM12.375 8.8125C12.0048 8.8125 11.707 9.1103 11.707 9.48047V12.375C11.707 12.5531 11.7766 12.7229 11.9019 12.8481L13.9058 14.8521C14.1674 15.1137 14.5904 15.1137 14.8493 14.8521C15.1081 14.5904 15.1109 14.1674 14.8493 13.9085L13.0402 12.0995V9.48047C13.0402 9.1103 12.7424 8.8125 12.3722 8.8125H12.375Z"
                         fill="white"
                    ></path>
               </svg>
          </template>
     </Paginate>
</template>
<script>
import { computed, reactive, watch } from "vue";
import { gamesStore } from "store/games";
import Slider from "components/games/slider/index.vue";
import Paginate from "components/games/paginate/index.vue";

export default {
     components: {
          Slider,
          Paginate
     },
     props: ["type"],
     setup() {
          const useGamesStore = gamesStore();

          const games = reactive({
               data: null
          });

          const pagination = reactive({
               current: null,
               last: null,
               next: null
          });

          const recentData = computed(() => {
               if (useGamesStore.allRecent) {
                    games.data = useGamesStore.allRecent["data"];
                    pagination["current"] =
                         useGamesStore.allRecent["current_page"];
                    pagination["last"] = useGamesStore.allRecent["last_page"];
                    const temp = useGamesStore.allRecent["next_page_url"];
                    pagination["next"] =
                         temp != null ? temp.slice(-1) : pagination["last"];
               }
               return useGamesStore.allRecent || null;
          });

          const onSeeMore = async () => {
               const data = {
                    game_name: "",
                    game_promo: 0,
                    game_type: [],
                    provider_id: params.provider_id,
                    page: pagination["next"],
                    itemsPerPage: 21,
                    sortType: params.sortType
               };

               await useGamesStore.getRecent(data, false, true);
          };

          watch(recentData, (newVal) => {
               if (newVal) {
                    games.data = newVal["data"];
                    pagination["current"] = newVal["current_page"];
                    pagination["last"] = newVal["last_page"];
                    const temp = newVal["next_page_url"];
                    pagination["next"] =
                         temp != null ? temp.slice(-1) : pagination["last"];
               }
          });

          return {
               games,
               onSeeMore,
               pagination
          };
     }
};
</script>

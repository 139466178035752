import {
     games as gamesAPI,
     gameType as gameTypeAPI,
     providers as providersAPI,
     favorites as favoritesAPI,
     recent as recentAPI,
     popular as popularAPI,
     getHeart as getHeartAPI,
     allProviders as allProvidersAPI
} from "@/api/games";
import { defineStore } from "pinia";
import _ from "underscore";

export const gamesStore = defineStore("games", {
     state: () => {
          return {
               allGames: {},
               loadingResult: false,
               loadingSeeMoreGames: {},
               loadingSortGames: {},
               loadingDropwdownGames: {},
               loadingCategoryGames: {},
               provider: null,
               allFavorites: null,
               allRecent: null,
               popular: null,
               groups: null,
               heart: null,
               gameFilter: {
                    page: 1,
                    game_type: [],
                    game_promo: 0,
                    game_name: "",
                    provider_id: [],
                    active: null,
                    sortType: "popularity"
               },
               allProvider: null,
               isActiveCoinType: null,
               isOpenGameTokenExpired: false
          };
     },
     actions: {
          clearResult() {
               if (this.allGames["result"] != null) {
                    this.$patch((state) => {
                         state.allGames["result"] = null;
                    });
               }
          },
          getGames(
               params,
               category,
               isReset = false,
               isPaginate = false,
               filterRequestType = null
          ) {
               if (!isReset && !isPaginate && this.allGames[category] != null) {
                    return;
               }

               this.$patch((state) => {
                    if (filterRequestType) {
                         switch (filterRequestType) {
                              case "sort":
                                   state.loadingSortGames[category] = true;
                                   break;
                              case "dropdown":
                                   state.loadingDropwdownGames[category] = true;
                                   break;
                              default:
                                   break;
                         }
                    }
               });

               return new Promise((resolve, reject) => {
                    this.$patch((state) => {
                         if (category == "result" || isReset) {
                              if (state.allGames[category] != null) {
                                   state.allGames[category] = null;
                              }
                              state.loadingResult = true;
                         }
                    });

                    gamesAPI(params)
                         .then((response) => {
                              const { games } = response;

                              if (!games) {
                                   reject("Failed to load data!");
                              }

                              this.$patch((state) => {
                                   if (
                                        [undefined, null].includes(
                                             state.allGames[category]
                                        )
                                   ) {
                                        state.allGames[category] = null;
                                        state.loadingSeeMoreGames[category] =
                                             false;
                                        state.loadingSortGames[category] =
                                             false;
                                        state.loadingDropwdownGames[category] =
                                             false;
                                   }

                                   if (state.allGames[category] == null) {
                                        state.allGames[category] = games;
                                        state.loadingResult = false;
                                        if (filterRequestType) {
                                             state.loadingSortGames[category] =
                                                  false;
                                             state.loadingDropwdownGames[
                                                  category
                                             ] = false;
                                        }
                                   } else {
                                        state.loadingSeeMoreGames[category] =
                                             true;
                                        setTimeout(() => {
                                             let gamesLoaded = isReset
                                                  ? []
                                                  : state.allGames[category]
                                                         .data;
                                             gamesLoaded.push(...games.data);

                                             if (
                                                  params["game_type"].length !=
                                                  0
                                             ) {
                                                  gamesLoaded = _.filter(
                                                       gamesLoaded,
                                                       function (data) {
                                                            return params[
                                                                 "game_type"
                                                            ].includes(
                                                                 data[
                                                                      "game_type"
                                                                 ]
                                                            );
                                                       }
                                                  );
                                             }

                                             state.allGames[category] = games;
                                             state.allGames[category]["data"] =
                                                  gamesLoaded;
                                             state.loadingSeeMoreGames[
                                                  category
                                             ] = false;
                                        }, 2000);
                                   }
                              });
                              resolve();
                         })
                         .catch((error) => {
                              reject(error);
                         });
               });
          },
          getProvider() {
               if (this.provider != null) {
                    return;
               }

               return new Promise((resolve, reject) => {
                    providersAPI()
                         .then((response) => {
                              const { provider } = response;

                              if (!provider) {
                                   reject("Failed to load data!");
                              }

                              this.$patch((state) => {
                                   const all = {
                                        name: "All",
                                        id: 0
                                   };

                                   state.provider = [all, ...provider];
                              });
                              resolve();
                         })
                         .catch((error) => {
                              this.$patch((state) => {
                                   if (error.response) {
                                        state.error_msg = error.response.data;
                                   }
                              });
                              // errorToast('Error to load data!', "top-right");
                              // console.error('Error to load games data!', error);
                              reject(error);
                         });
               });
          },
          getFavorites(
               params,
               isReset = false,
               isPaginate = false,
               filterRequestType = null
          ) {
               if (!isReset && !isPaginate && this.allFavorites != null) {
                    return;
               }

               this.$patch((state) => {
                    if (filterRequestType) {
                         switch (filterRequestType) {
                              case "sort":
                                   state.loadingSortGames["favorite"] = true;
                                   break;
                              case "dropdown":
                                   state.loadingDropwdownGames["favorite"] =
                                        true;
                                   break;
                              default:
                                   break;
                         }
                    }
               });

               return new Promise((resolve, reject) => {
                    this.$patch((state) => {
                         if (isReset) {
                              if (state.allFavorites != null) {
                                   state.allFavorites = null;
                              }
                         }
                    });

                    favoritesAPI(params)
                         .then((response) => {
                              const { games } = response;

                              if (!games) {
                                   reject("Failed to load data!");
                              }

                              this.$patch((state) => {
                                   if (
                                        [undefined, null].includes(
                                             state.loadingSeeMoreGames[
                                                  "favorite"
                                             ]
                                        )
                                   ) {
                                        state.loadingSeeMoreGames["favorite"] =
                                             false;
                                        state.loadingSortGames["favorite"] =
                                             false;
                                        state.loadingDropwdownGames[
                                             "favorite"
                                        ] = false;
                                   }

                                   if (state.allFavorites == null) {
                                        state.allFavorites = games;
                                        if (filterRequestType) {
                                             state.loadingSortGames[
                                                  "favorite"
                                             ] = false;
                                             state.loadingDropwdownGames[
                                                  "favorite"
                                             ] = false;
                                        }
                                   } else {
                                        state.loadingSeeMoreGames["favorite"] =
                                             true;
                                        setTimeout(() => {
                                             let gamesLoaded = isReset
                                                  ? []
                                                  : state.allFavorites.data;
                                             gamesLoaded.push(...games.data);

                                             if (
                                                  params["game_type"].length !=
                                                  0
                                             ) {
                                                  gamesLoaded = _.filter(
                                                       gamesLoaded,
                                                       function (data) {
                                                            return params[
                                                                 "game_type"
                                                            ].includes(
                                                                 data[
                                                                      "game_type"
                                                                 ]
                                                            );
                                                       }
                                                  );
                                             }

                                             state.allFavorites = games;
                                             state.allFavorites["data"] =
                                                  gamesLoaded;
                                             state.loadingSeeMoreGames[
                                                  "favorite"
                                             ] = false;
                                        }, 2000);
                                   }
                              });
                              resolve();
                         })
                         .catch((error) => {
                              reject(error);
                         });
               });
          },
          getRecent(
               params,
               isReset = false,
               isPaginate = false,
               filterRequestType = null
          ) {
               if (!isReset && !isPaginate && this.allRecent != null) {
                    return;
               }

               this.$patch((state) => {
                    if (filterRequestType) {
                         switch (filterRequestType) {
                              case "sort":
                                   state.loadingSortGames["recent"] = true;
                                   break;
                              case "dropdown":
                                   state.loadingDropwdownGames["recent"] = true;
                                   break;
                              default:
                                   break;
                         }
                    }
               });

               return new Promise((resolve, reject) => {
                    this.$patch((state) => {
                         if (isReset) {
                              if (state.allRecent != null) {
                                   state.allRecent = null;
                              }
                         }
                    });

                    recentAPI(params)
                         .then((response) => {
                              const { games } = response;

                              if (!games) {
                                   reject("Failed to load data!");
                              }

                              this.$patch((state) => {
                                   if (
                                        [undefined, null].includes(
                                             state.loadingSeeMoreGames["recent"]
                                        )
                                   ) {
                                        state.loadingSeeMoreGames["recent"] =
                                             false;
                                        state.loadingSortGames["recent"] =
                                             false;
                                        state.loadingDropwdownGames["recent"] =
                                             false;
                                   }

                                   if (state.allRecent == null) {
                                        state.allRecent = games;
                                        if (filterRequestType) {
                                             state.loadingSortGames["recent"] =
                                                  false;
                                             state.loadingDropwdownGames[
                                                  "recent"
                                             ] = false;
                                        }
                                   } else {
                                        state.loadingSeeMoreGames["recent"] =
                                             true;
                                        setTimeout(() => {
                                             let gamesLoaded = isReset
                                                  ? []
                                                  : state.allRecent.data;
                                             gamesLoaded.push(...games.data);

                                             if (
                                                  params["game_type"].length !=
                                                  0
                                             ) {
                                                  gamesLoaded = _.filter(
                                                       gamesLoaded,
                                                       function (data) {
                                                            return params[
                                                                 "game_type"
                                                            ].includes(
                                                                 data[
                                                                      "game_type"
                                                                 ]
                                                            );
                                                       }
                                                  );
                                             }

                                             state.allRecent = games;
                                             state.allRecent["data"] =
                                                  gamesLoaded;
                                             state.loadingSeeMoreGames[
                                                  "recent"
                                             ] = false;
                                        }, 2000);
                                   }
                              });
                              resolve();
                         })
                         .catch((error) => {
                              reject(error);
                         });
               });
          },
          getPopular(data, isReset = false) {
               if (!isReset && this.popular != null) {
                    return null;
               }
               return new Promise((resolve, reject) => {
                    popularAPI(data)
                         .then((response) => {
                              if (!response) {
                                   reject("Failed to load data!");
                              }

                              this.$patch((state) => {
                                   state.popular = response;
                              });
                              resolve();
                         })
                         .catch((error) => {
                              this.$patch((state) => {
                                   if (error.response) {
                                        state.error_msg = error.response.data;
                                   }
                              });
                              reject(error);
                         });
               });
          },
          getGameType() {
               if (this.groups != null) {
                    return;
               }

               return new Promise((resolve, reject) => {
                    gameTypeAPI()
                         .then((response) => {
                              const { groups } = response;

                              if (!groups) {
                                   reject("Failed to load data!");
                              }

                              this.$patch((state) => {
                                   state.groups = groups;
                              });
                              resolve();
                         })
                         .catch((error) => {
                              this.$patch((state) => {
                                   if (error.response) {
                                        state.error_msg = error.response.data;
                                   }
                              });
                              reject(error);
                         });
               });
          },
          getHeart(isReset = false) {
               if (!isReset && this.heart != null) {
                    return;
               }
               return new Promise((resolve, reject) => {
                    getHeartAPI()
                         .then((response) => {
                              const { games } = response;
                              if (!games) {
                                   reject("Failed to load data!");
                              }

                              this.$patch((state) => {
                                   state.heart = games;
                              });
                              resolve();
                         })
                         .catch((error) => {
                              this.$patch((state) => {
                                   if (error.response) {
                                        state.error_msg = error.response.data;
                                   }
                              });
                              reject(error);
                         });
               });
          },
          setGameFilter(filter, active) {
               this.$patch((state) => {
                    const obj = Object.keys(filter);
                    const objFilter = Object.keys(state.gameFilter);

                    state.gameFilter["active"] = active;

                    for (let index = 0; index < obj.length; index++) {
                         if (objFilter.includes(obj[index])) {
                              state.gameFilter[obj[index]] = filter[obj[index]];
                         }
                    }
               });
          },
          getAllProvider() {
               if (this.allProvider != null) {
                    return;
               }

               return new Promise((resolve, reject) => {
                    allProvidersAPI()
                         .then((response) => {
                              const { provider } = response;

                              if (!provider) {
                                   reject("Failed to load data!");
                              }

                              this.$patch((state) => {
                                   const filter = _.filter(
                                        provider,
                                        function (params) {
                                             return (
                                                  params["icon"] != null &&
                                                  params["icon_mobile"] != null
                                             );
                                        }
                                   );
                                   state.allProvider = filter;
                              });
                              resolve();
                         })
                         .catch((error) => {
                              this.$patch((state) => {
                                   state.allProvider = [];
                              });
                              reject(error);
                         });
               });
          },
          setGameToken(data) {
               this.$patch((state) => {
                    state.isOpenGameTokenExpired = data;
               });
          }
     }
});

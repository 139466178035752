import { format, subYears } from "date-fns";

export const useFormatter = () => {
     const money = (value) => {
          let val = (value / 1).toFixed(2);
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
     };

     const date = (value) => {
          const val = format(new Date(value), "dd MMMM yyyy");
          return val;
     };

     const date2 = (value) => {
          const val = format(new Date(value), "dd/MM/yyyy");
          return val;
     };

     const date3 = (value) => {
          const val = format(new Date(value), "yyyy-MM-dd");
          return val;
     };

     const date4 = (value) => {
          const val = format(new Date(value), "MMM dd, yyyy hh:mm a");
          return val;
     };

     const date5 = (value) => {
          const val = format(new Date(value), "yyyy-MM-dd hh:mm:ss");
          return val;
     };

     const date6 = (value) => {
          const val = format(new Date(value), "MMM dd, yyyy");
          return val;
     };

     const date7 = (value) => {
          const val = format(new Date(value), "eeee, dd MMMM yyyy hh:mm");
          return val;
     };

     const date8 = (value) => {
          const val = format(new Date(value), "MMM dd, yyyy hh:mm a");
          return val;
     };

     const time = (value) => {
          const val = format(new Date(value), "hh:mm a");
          return val;
     };

     const dateday = (value) => {
          const options = Intl.DateTimeFormat("en-Us", {
               weekday: "long"
          }).format(new Date(value));
          return options;
     };
     const datetime = (value) => {
          const val = format(new Date(value), "hh:mm");
          return val;
     };
     const datetime2 = (value) => {
          const val = format(new Date(value), "hh:mm:ss");
          return val;
     };

     const datetime3 = (value) => {
          const val = format(new Date(value), "dd/MM/yyyy hh:mm:ss");
          return val;
     };

     const datetime4 = (value) => {
          const val = format(new Date(value), "HH:mm");
          return val;
     };

     const today = () => {
          return new Date();
     };

     const getDayOfWeek = (value) => {
          const val = format(new Date(value), "eeee");
          return val;
     };

     const getDayOfMonth = (value) => {
          const val = format(new Date(value), "d");
          return val;
     };

     const getTime = (value) => {
          const val = format(new Date(value), "hh:mm a");
          return val;
     };

     const eighteenYearsAgo = () => {
          const eighteenYearsAgo = subYears(new Date(), 18);
          const formattedDate = format(eighteenYearsAgo, "yyyy-MM-dd");
          return formattedDate;
     };

     const numbersFormatter = (num, digits) => {
          const lookup = [
               { value: 1, symbol: "" },
               { value: 1e3, symbol: "K" },
               { value: 1e6, symbol: "M" },
               { value: 1e9, symbol: "G" },
               { value: 1e12, symbol: "T" },
               { value: 1e15, symbol: "P" },
               { value: 1e18, symbol: "E" }
          ];
          const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
          var item = lookup
               .slice()
               .reverse()
               .find(function (item) {
                    return num >= item.value;
               });
          return item
               ? (num / item.value).toFixed(digits).replace(rx, "$1") +
                      item.symbol
               : "0";
     };
     const checkDecimal = (num) => {
          const result = num - Math.floor(num) !== 0;
          var isDecimal = false;
          if (result) {
               isDecimal = true;
               return isDecimal;
          }
     };

     const urlTitleFormatter = (title) => {
          const stringWithoutTrailingSpace = title.trim();
          const stringWithHyphens = stringWithoutTrailingSpace.replace(
               / /g,
               "-"
          );
          const removeSpecialChar = stringWithHyphens.replace(
               /[^a-zA-Z0-9\-]/g,
               ""
          );
          const removeNonBreakingSpace = removeSpecialChar.replace(/\s/g, "-");
          return removeNonBreakingSpace;
     };

     const selfExclusionTimeFormat = (data) => {
          if (data["type"] == "days") {
               return (
                    data["value"] + " day" + (data["value"] == 1 ? "." : "s.")
               );
          } else {
               const seconds = data["value"] * 60;
               const timeString = date(
                    "H:i:s",
                    strtotime("00:00:00") + seconds
               );
               return (
                    timeString +
                    (seconds < 60
                         ? " minute."
                         : seconds < 3600
                           ? " minutes."
                           : seconds < 7200
                             ? " hour."
                             : " hours.")
               );
          }
     };

     function removeUSAPrefix(phoneNumber) {
          console.log(phoneNumber);
          console.log(phoneNumber.replace(/\+1[\s-]*/g, ""));
          return phoneNumber.replace(/\+1[\s-]*/g, "");
     }

     return {
          money,
          today,
          getDayOfWeek,
          getDayOfMonth,
          getTime,
          date,
          date2,
          date3,
          date4,
          date5,
          date6,
          date7,
          date8,
          dateday,
          datetime,
          datetime2,
          datetime3,
          datetime4,
          numbersFormatter,
          checkDecimal,
          urlTitleFormatter,
          eighteenYearsAgo,
          selfExclusionTimeFormat,
          removeUSAPrefix,
          time
     };
};

<template>
     <InertiaHead title="Thanks"></InertiaHead>
     <div class="w-full h-full relative">
          <div
               class="z-0 w-full h-100-vh welcome-bg-container bg-background-2 fixed top-0 left-0"
          ></div>
          <div class="w-full bg-transparent relative">
               <div
                    class="w-full h-full flex flex-col justify-center items-center px-5 xs:px-8 sm:px-18 md:px-24 lg:px-28 xl:px-32 pb-16 sm:pb-18 md:pb-20 lg:pb-28 xl:pb-32"
               >
                    <div
                         class="bg-gradient-promo-detail rounded-xl flex flex-col w-full lg:w-7/12 2xl:w-5/12 mt-10 sm:mt-12 md:mt-18 lg:mt-18"
                    >
                         <div
                              class="px-5 md:px-7 lg:px-10 py-10 flex flex-col justify-center items-center"
                         >
                              <h3
                                   class="text-white font-helvetica tracking-wide text-center text-2xl xs:text-3xl sm:text-5xl md:text-6xl lg:text-4xl xl:text-5xl 2xl:text-4xl leading-xl xs:leading-3xl sm:leading-5xl md:leading-5xl lg:leading-4xl xl:leading-5xl 2xl:leading-4xl uppercase"
                              >
                                   Thank you for purchasing!
                              </h3>
                              <p
                                   class="text-white font-helveticaLight tracking-wide mt-5 md:mt-8 text-center md:text-start font-normal text-sm sm:text-lg md:text-xl lg:text-lg xl:text-xl"
                              >
                                   Your coins amount of
                                   <span class="font-bold text-secondary-3"
                                        >{{
                                             gold_coins ? money(gold_coins) : 0
                                        }}
                                        GC</span
                                   >&nbsp;
                                   <span class="font-bold text-tertiary-3"
                                        >+
                                        {{
                                             silver_coins
                                                  ? money(silver_coins)
                                                  : 0
                                        }}
                                        SC</span
                                   >
                                   is added in your account. You can begin
                                   playing. Good luck and have fun in Moozi!
                              </p>
                              <div
                                   class="w-full flex justify-center items-center mt-8 md:mt-14"
                              >
                                   <div
                                        @click="play"
                                        class="rounded-full font-roboto font-extrabold tracking-wider px-10 py-3 bg-secondary-3 text-primary-1 text-sm sm:text-lg md:text-xl lg:text-lg xl:text-xl cursor-pointer"
                                   >
                                        Play Now
                                   </div>
                              </div>
                              <img
                                   :src="
                                        baseUrl.img +
                                        '/assets/img/cow-with-logo.png'
                                   "
                                   alt="cow"
                                   class="mt-8 md:mt-16 mb-8"
                              />
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>
<script>
import { Head as InertiaHead } from "@inertiajs/vue3";
import { useRouter, useRoute } from "vue-router";
import { ref } from "vue";
import { useFormatter } from "composables/use-formatter.js";
import { baseUrl } from "config/services.js";

export default {
     components: {
          InertiaHead
     },
     setup() {
          const router = useRouter();
          const route = useRoute();
          const { money } = useFormatter();

          const gold_coins = ref(route.params.gc);
          const silver_coins = ref(route.params.sc);

          const play = () => {
               router.push({
                    path: "/games"
               });
          };

          return {
               play,
               money,
               gold_coins,
               silver_coins,
               baseUrl
          };
     }
};
</script>

export default function device() {
     const deviceType = () => {
          var type =
               /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
               )
                    ? "mobile"
                    : "desktop";

          return type;
     };

     const isIOS = () => {
          return (
               /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
          );
     };

     return {
          deviceType,
          isIOS
     };
}

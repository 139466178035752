<template>
     <div
          v-if="enrolled != null && enrolled?.account?.length"
          class="w-full"
     >
          <div
               v-if="
                    walletRedeemCount == 0 &&
                    enrolled?.account[0].accountStatus == '40'
               "
               class="w-full"
          >
               <div
                    class="border border-shade-10 py-3 px-4 w-full text-center text-shade-10 rounded-lg mb-4 font-helveticaLight tracking-wide font-normal text-sm sm:text-lg md:text-xl lg:text-sm xl:text-base"
               >
                    No bank connected.
               </div>
               <div>
                    <span
                         @click="() => openPaymentCheckout()"
                         class="font-bold underline text-base cursor-pointer"
                    >
                         <span class="text-lg">+</span>
                         <span
                              class="text-sm sm:text-base md:text-lg lg:text-base xl:text-lg"
                         >
                              Connect your bank
                         </span>
                    </span>
               </div>
          </div>
          <div
               v-for="(item, key) in enrolled?.account"
               :key="key"
               class="mb-2 cursor-pointer w-full"
          >
               <div
                    v-if="item.accountStatus != '40'"
                    @click="onUpdateID(item.fdAccountID)"
                    class="fiserv-list-account w-full bg-shade-9 text-gray-900 border-2 border-solid border-shade-7 p-3 rounded-md transition-colors duration-300 wallet-list flex justify-start items-center gap-2"
               >
                    <input
                         v-model="form.accountId"
                         :id="item.fdAccountID"
                         :key="key"
                         type="radio"
                         name="existingCard"
                         :value="item.fdAccountID"
                         class="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300 cursor-pointer"
                    />
                    <div>
                         <p
                              class="text-sm font-inter font-medium mb-0 flex flex-row gap-2"
                         >
                              <span class="font-normal">{{
                                   item.userIdentificationDetails.bankName
                              }}</span>
                         </p>
                         <p
                              class="text-sm font-inter font-medium mb-0 flex flex-row gap-2"
                         >
                              <span class="font-normal">{{
                                   item.userIdentificationDetails
                                        .bankAccountActualLastFour ??
                                   item.userIdentificationDetails.accountNumber
                              }}</span>
                         </p>
                    </div>
               </div>
          </div>
     </div>
     <div
          v-else-if="enrolled == null"
          class="h-32 w-full"
     >
          <div
               v-if="loading"
               class="w-full h-full flex items-center justify-center"
          >
               <svg
                    aria-hidden="true"
                    class="w-8 h-8 text-black animate-spin fill-secondary-1"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
               >
                    <path
                         d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                         fill="currentColor"
                    ></path>
                    <path
                         d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                         fill="currentFill"
                    ></path>
               </svg>
          </div>
     </div>
     <div
          v-else
          class="w-full mt-3"
     >
          <div
               class="border border-shade-10 py-3 px-4 w-full text-center text-shade-10 text-lg rounded-lg mb-4 font-helveticaLight tracking-wide font-normal text-sm sm:text-lg md:text-xl lg:text-sm xl:text-base"
          >
               No bank connected.
          </div>
          <div>
               <span
                    @click="() => fetchSDK('bank')"
                    class="font-bold underline text-base cursor-pointer"
               >
                    <span class="text-lg">+</span>
                    <span
                         class="text-sm sm:text-base md:text-lg lg:text-base xl:text-lg"
                    >
                         Connect your bank
                    </span>
               </span>
          </div>
     </div>
</template>
<script>
import { computed, onMounted, ref, reactive } from "vue";
import { connectPayStore } from "store/connectPay.js";
import { useKYC } from "composables/use-kyc.js";
import {
     initConnectPay as initConnectPayAPI,
     getNonDoc as getNonDocAPI
} from "api/connect_pay.js";
import { fiservUrl } from "config/services.js";
import { authStore } from "store/auth.js";
import eventBus from "plugins/event.js";
import { useToaster } from "composables/use-toaster.js";
import _ from "underscore";
import { useFormatter } from "composables/use-formatter.js";

export default {
     props: ["form", "onUpdateID", "setLoading"],
     setup(props) {
          const { successToast, errorToast } = useToaster();
          const { removeUSAPrefix } = useFormatter();

          const { open_kyc_bank } = useKYC();

          var extraParams = reactive({
               firstName: "",
               lastName: "",
               email: "",
               street: "",
               city: "",
               state: "",
               postalCode: ""
          });

          const useAuthStore = authStore();
          const useconnectPayStore = connectPayStore();

          const loading = ref(false);
          const isAddAccountPanelOpen = ref(false);

          const isUser = computed(() => {
               return useAuthStore.user;
          });

          const enrolled = computed(() => {
               return useconnectPayStore.walletRedeem;
          });

          const walletRedeemCount = computed(() => {
               return useconnectPayStore.walletRedeemCount;
          });

          const getSDKConfig = (data, type, accountId) => {
               const params = {
                    accessToken: data["currentSession"]["security"]["tokenID"],
                    fdCustomerId: ["bank", "manual"].includes(type)
                         ? data["fdCustomerID"]
                         : enrolled.value.customer.fdCustomerID,
                    encryptionKey:
                         data["currentSession"]["security"]["publicKey"]
               };

               switch (type) {
                    case "bank":
                         params["configId"] = fiservUrl.bank;
                         break;
                    case "manual":
                         params["configId"] = fiservUrl.manual;
                         break;
                    case "edit":
                         params["configId"] = fiservUrl.edit;
                         break;
                    case "close":
                         params["configId"] = fiservUrl.close;
                         break;
                    default:
                         break;
               }

               if (accountId != null) {
                    params["accountID"] = accountId;
               }

               return params;
          };

          const getData = async () => {
               const non_doc = _.filter(isUser.value.kyc, function (params) {
                    return params["type"].toLowerCase() == "usa non-doc";
               });

               console.log(non_doc);

               let jsonAddress = null;
               let jsonUser = null;

               if (non_doc.length >= 1) {
                    const json = JSON.parse(non_doc[0].extracted_docs);

                    if (json) {
                         if (json.extractedDoc.addresses.length >= 1) {
                              jsonAddress = json.extractedDoc.addresses[0];
                         }

                         jsonUser = json.extractedDoc;
                    } else {
                         const temp = await getNonDoc(non_doc[0].applicant_id);

                         if (!temp?.status) {
                              errorToast("Invalid KYC", "top-right");
                              setTimeout(() => {
                                   location.reload();
                                   props.setLoading(false);
                              }, 1500);
                         } else {
                              const json = JSON.parse(temp.extracted_docs);

                              if (json.extractedDoc.addresses.length >= 1) {
                                   jsonAddress = json.extractedDoc.addresses[0];
                              }
                         }
                    }
               }

               return {
                    address: jsonAddress,
                    user: jsonUser
               };
          };

          const getNonDoc = async (id) => {
               return await getNonDocAPI(id)
                    .then(async (res) => {
                         return res;
                    })
                    .catch(async (res) => {
                         return res;
                    });
          };

          const setExtraParams = async () => {
               const data = await getData();

               extraParams.firstName = data.user.firstName;
               extraParams.lastName = data.user.lastName;
               extraParams.email = data.user.email ?? isUser.value.email;
               extraParams["userPhone[0].type"] = "HOME";
               extraParams["userPhone[0].primary"] = "Y";
               extraParams["userPhone[0].number"] = "+" + data.user.phone;

               extraParams.street = data.address.street;
               extraParams.city = data.address.town;
               extraParams.state = data.address.state;

               if (data.address.postCode) {
                    let splitPostcode = data.address.postCode.split("-");
                    extraParams.postalCode = splitPostcode[0];
                    // extraParams.postalCode = data.address.postCode;
               }

               if (extraParams["userPhone[0].number"]) {
                    extraParams["userPhone[0].number"] = removeUSAPrefix(
                         extraParams["userPhone[0].number"]
                    );
               }

               if (typeof data.user.tin !== "undefined") {
                    extraParams.ssn = data.user.tin;
               }

               return extraParams;
          };

          const fetchSDK = async (type, accountId = null) => {
               if (open_kyc_bank() == true) {
                    return;
               }

               let params = {};
               if (["bank", "manual"].includes(type)) {
                    if (walletRedeemCount.value >= 1) {
                         return;
                    }
                    isAddAccountPanelOpen.value = true;
                    loading.value = true;
               } else {
                    params = { fdAccountID: accountId };
               }

               props.setLoading(true);

               await initConnectPayAPI(params)
                    .then(async (res) => {
                         if (res) {
                              let CP = await CPSDK(res["api_key"], true);

                              const sdkConfiguration = getSDKConfig(
                                   res,
                                   type,
                                   accountId
                              );

                              const extraData = await setExtraParams(res);

                              console.log(extraData);

                              if (
                                   extraData["city"] == null ||
                                   extraData["city"] == "" ||
                                   extraData["state"] == null ||
                                   extraData["state"] == "" ||
                                   extraData["postalCode"] == null ||
                                   extraData["postalCode"] == "" ||
                                   extraData["street"] == null ||
                                   extraData["street"] == ""
                              ) {
                                   errorToast(
                                        "Address Incomplete!",
                                        "top-right"
                                   );
                                   props.setLoading(false);
                                   return;
                              }

                              if (type == "bank") {
                                   props.setLoading(false);
                                   await addBank(
                                        CP,
                                        sdkConfiguration,
                                        extraData
                                   );
                              }
                         }
                    })
                    .catch(() => {
                         props.setLoading(false);
                    });
          };

          const addBank = async (CP, sdkConfiguration, extraData) => {
               console.log("===============================================");
               console.log("STREAMLINE ENROLLMENT() - LINK BANK ACCOUNT");
               // console.log(sdkConfiguration);
               // console.log(extraData);
               //    32678005-8e7f-4d12-8298-cd40e103866a
               openBankWallet();
               const addBank = await CP.StreamlinedEnrollment(
                    sdkConfiguration,
                    extraData,
                    async function (response) {
                         const res =
                              typeof response == "object"
                                   ? response
                                   : JSON.parse(response);

                         if (
                              res.transactionStatusDescription ==
                              "User Cancelled"
                         ) {
                              //   isAddAccountPanelOpen.value = false;
                              closeBankWallet();
                         } else if (statusMsg.includes(res.transactionStatus)) {
                              console.error(res.transactionStatusDescription);
                              errorToast(
                                   res.transactionStatusDescription,
                                   "top-right"
                              );
                              fetchSDK("bank");
                         } else {
                              successToast(
                                   "Successfully Linked Bank Account!",
                                   "top-right"
                              );

                              await useconnectPayStore.getWalletRedeem(true);
                              await useconnectPayStore.getEnrolled(true);
                              closeBankWallet();
                              // purchase_loading.value = true;
                              // const saveOldEnrolledList = enrolled.value?.account;
                              // await useconnectPayStore.getEnrolled(true);
                              // const newEnrolledList = enrolled.value?.account;

                              // const pluckOld = _.pluck(saveOldEnrolledList, 'fdAccountID');
                              // const pluckNew = _.pluck(newEnrolledList, 'fdAccountID');

                              // closeBankWallet();
                              // purchase_loading.value = false;

                              // const dif = _.difference(pluckNew, pluckOld);
                              // console.log(dif);

                              // const filter = _.filter(newEnrolledList, function (params) {
                              //      return params['fdAccountID'] == dif[0]
                              // })

                              // setSelectedWallet(filter[0]);
                              // openConfirmationModal();
                         }
                    }
               );

               addBank.start("sdk-container-bank");
               loading.value = false;
          };

          const openBankWallet = () => {
               eventBus.emit("open:bank_wallet");
          };

          const closeBankWallet = () => {
               eventBus.emit("close:bank_wallet");
          };

          onMounted(async () => {
               loading.value = true;

               const isExist = document.querySelector("#fiserv-import");
               if (isExist == null) {
                    var script = document.createElement("script");
                    script.id = "fiserv-import";
                    script.src =
                         import.meta.env.VITE_ENV == "production"
                              ? "https://prod.api.firstdata.com/gateway/v2/connectpay/static/v1/js/PaymentSDK.js"
                              : "https://cat.api.firstdata.com/gateway/v2/connectpay/static/v1/js/PaymentSDK.js";
                    document.body.appendChild(script);
               }

               await useconnectPayStore.getWalletRedeem();

               loading.value = false;
          });

          return {
               enrolled,
               walletRedeemCount,
               loading,
               fetchSDK
          };
     }
};
</script>

<template>
     <InertiaHead title="Game Details"></InertiaHead>
     <div class="w-full h-full relative mt-0">
          <div
               class="w-full h-full flex flex-col justify-center items-center mx-1.5"
          >
               <div
                    v-if="gamesDetails"
                    class="w-full flex flex-col xl:flex-row items-center xl:items-start md:gap-10 xl:gap-5 2xl:gap-12 3xl:gap-16 mt-8 sm:mt-10 md:mt-14 lg:mt-14 xl:mt-18"
               >
                    <div class="w-full md:w-6/12 xl:w-5/12 2xl:w-4/12 relative">
                         <div
                              class="absolute block md:hidden top-2 right-2 rounded-xl z-[8]"
                         >
                              <span
                                   @click="
                                        onHeart(
                                             gamesDetails?.id,
                                             heartData?.includes(
                                                  gamesDetails?.id
                                             )
                                        )
                                   "
                                   :class="
                                        heartData.includes(gamesDetails?.id)
                                             ? 'active'
                                             : ''
                                   "
                                   class="text-2xl md:text-3xl icon-addFavorites cursor-pointer"
                              ></span>
                         </div>
                         <img
                              v-if="gamesDetails?.game_pic"
                              :src="gamesDetails?.game_pic"
                              class="w-full rounded-t-3xl md:rounded-3xl"
                         />
                         <img
                              v-else
                              :src="
                                   baseUrl.img +
                                   '/assets/img/loader/popular-loader.png'
                              "
                              class="w-full rounded-3xl"
                         />
                    </div>
                    <div class="w-full xl:w-7/12 2xl:w-8/12 relative">
                         <div
                              class="absolute hidden md:block top-2 right-2 rounded-xl z-[8]"
                         >
                              <span
                                   @click="
                                        onHeart(
                                             gamesDetails?.id,
                                             heartData?.includes(
                                                  gamesDetails?.id
                                             )
                                        )
                                   "
                                   :class="
                                        heartData.includes(gamesDetails?.id)
                                             ? 'active'
                                             : ''
                                   "
                                   class="text-2xl md:text-3xl icon-addFavorites cursor-pointer"
                              ></span>
                         </div>
                         <div
                              class="bg-moozi-2 rounded-b-xl md:rounded-xl p-5 lg:p-8 flex flex-col justify-center items-center shadow-box"
                         >
                              <p
                                   class="mb-4 lg:mb-8 font-helvetica tracking-wide font-medium text-white text-2xl xs:text-3xl sm:text-4xl lg:text-3xl xl:text-4xl 2xl:text-4xl lg:leading-5 xl:leading-6 2xl:leading-7 text-center uppercase"
                              >
                                   {{ gamesDetails?.game_name }}
                              </p>
                              <div
                                   v-if="isUser && !checkActionRestricted()"
                                   class="flex flex-col md:flex-row justify-center gap-3 lg:gap-5 w-full"
                              >
                                   <div
                                        @click="
                                             () =>
                                                  openLaunchConfirmation(
                                                       gamesDetails,
                                                       'gold'
                                                  )
                                        "
                                        class="relative w-full lg:w-4/12 rounded-full font-roboto font-extrabold tracking-wider px-16 py-3 bg-secondary-3 text-primary-1 text-sm sm:text-lg md:text-xl lg:text-lg xl:text-xl cursor-pointer flex flex-row justify-center items-center gap-3"
                                   >
                                        <span> Launch </span>
                                        <img
                                             class="absolute right-2 cursor-pointer -ml-2 w-8 2xl:w-auto"
                                             :src="
                                                  baseUrl.img +
                                                  '/assets/img/header/GC.svg'
                                             "
                                             alt=""
                                             style="
                                                  filter: drop-shadow(
                                                       0px 4px 10px
                                                            rgba(0, 0, 0, 0.15)
                                                  );
                                             "
                                        />
                                   </div>
                                   <div
                                        @click="
                                             () =>
                                                  openLaunchConfirmation(
                                                       gamesDetails,
                                                       'silver'
                                                  )
                                        "
                                        class="relative w-full lg:w-4/12 rounded-full font-roboto font-extrabold tracking-wider px-16 py-3 bg-tertiary-3 text-primary-1 text-sm sm:text-lg md:text-xl lg:text-lg xl:text-xl cursor-pointer flex flex-row justify-center items-center gap-3"
                                   >
                                        <span> Launch </span>
                                        <img
                                             class="absolute right-2 cursor-pointer -ml-2 w-8 2xl:w-auto"
                                             :src="
                                                  baseUrl.img +
                                                  '/assets/img/header/SC.svg'
                                             "
                                             alt=""
                                             style="
                                                  filter: drop-shadow(
                                                       0px 4px 10px
                                                            rgba(0, 0, 0, 0.15)
                                                  );
                                             "
                                        />
                                   </div>
                              </div>
                              <div>
                                   <p
                                        v-html="gamesDetails?.game_description"
                                        :class="
                                             gamesDetails?.game_description
                                                  ? 'mt-6'
                                                  : ''
                                        "
                                        class="text-white font-roboto tracking-wide text-start text-sm lg:text-base"
                                   ></p>
                              </div>
                         </div>
                         <div class="flex flex-col gap-3 w-full mt-3 sm:mt-5">
                              <div
                                   class="flex flex-col sm:flex-row gap-3 w-full"
                              >
                                   <div
                                        class="h-14 sm:h-auto bg-moozi-2 rounded-xl px-4 py-3 w-full sm:w-1/2 shadow-box"
                                   >
                                        <p
                                             class="flex items-center gap-2 text-white font-roboto font-extrabold tracking-wider text-sm lg:text-base uppercase"
                                        >
                                             <span class="text-background-3"
                                                  >Game type:</span
                                             >
                                             <span
                                                  class="text-3xl"
                                                  :class="
                                                       'icon-' +
                                                       gamesDetails.game_type
                                                  "
                                             ></span>
                                             {{ gamesDetails?.game_type }}
                                        </p>
                                   </div>
                                   <div
                                        class="h-14 sm:h-auto flex items-center bg-moozi-2 rounded-xl px-4 py-3 w-full sm:w-1/2 shadow-box"
                                   >
                                        <p
                                             class="text-white font-roboto font-extrabold tracking-wider text-sm lg:text-base uppercase"
                                        >
                                             <span class="text-background-3"
                                                  >Provider:</span
                                             >
                                             {{ provider[0].name }}
                                        </p>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
               <div class="w-full mt-10">
                    <Slider
                         :data="similarGames"
                         title="Similar Games"
                         :overideOnlick="
                              (game_name, id) => goToDetail(game_name, id)
                         "
                    >
                         <template v-slot:icon>
                              <svg
                                   class="w-7"
                                   viewBox="0 0 24 24"
                                   fill="none"
                                   xmlns="http://www.w3.org/2000/svg"
                              >
                                   <path
                                        d="M12.129 18.1626C10.8245 18.1626 9.5199 18.1626 8.21534 18.1626C7.11148 18.1626 6.04776 17.9418 5.0844 17.3799C3.77984 16.6172 2.83654 15.5334 2.35486 14.0883C1.77282 12.3623 1.89324 10.6563 2.81647 9.07076C3.75977 7.42499 5.18474 6.42148 7.07134 6.10035C7.53295 6.02007 7.99457 6 8.45618 6C10.985 6 13.5139 6 16.0427 6C16.9258 6 17.7888 6.14049 18.6117 6.50176C20.2374 7.20422 21.3613 8.40844 21.9433 10.0743C22.5454 11.8204 22.3849 13.5063 21.4817 15.1119C20.5384 16.7778 19.0934 17.7612 17.2268 18.0623C16.7652 18.1425 16.3036 18.1626 15.842 18.1626C14.5976 18.1626 13.3734 18.1626 12.129 18.1626ZM12.129 16.6573C13.4336 16.6573 14.7181 16.6573 16.0226 16.6573C16.3839 16.6573 16.7652 16.6172 17.1265 16.557C19.8159 16.0151 21.3211 13.3457 20.5786 10.8169C19.9965 8.82991 18.2705 7.54541 16.1832 7.52534C13.4938 7.52534 10.7843 7.52534 8.09492 7.52534C7.01113 7.52534 6.00763 7.86654 5.16468 8.569C3.96047 9.57251 3.41858 10.8972 3.51893 12.4426C3.59921 13.6267 4.08089 14.6503 4.96397 15.4531C5.86713 16.276 6.95092 16.6573 8.15513 16.6573C9.47976 16.6573 10.8044 16.6573 12.1089 16.6573H12.129Z"
                                        fill="white"
                                   ></path>
                                   <path
                                        d="M7.85413 11.3182C7.85413 10.9368 7.83406 10.5956 7.85413 10.2544C7.89427 9.71254 8.39602 9.43155 8.8777 9.63226C9.19883 9.77275 9.31925 10.0337 9.31925 10.3548C9.31925 10.6759 9.31925 10.977 9.31925 11.3182C9.64037 11.3182 9.94142 11.3182 10.2425 11.3182C10.5435 11.3182 10.8044 11.3984 10.9851 11.6794C11.2861 12.2213 10.9249 12.8034 10.2826 12.8234C9.98156 12.8234 9.68051 12.8234 9.33932 12.8234C9.33932 13.1446 9.33932 13.4456 9.33932 13.7467C9.33932 14.0277 9.27911 14.2886 9.0182 14.4491C8.53651 14.7703 7.91434 14.4893 7.85413 13.9072C7.81399 13.566 7.85413 13.2048 7.85413 12.8034C7.51294 12.8034 7.19181 12.8034 6.89076 12.8034C6.24851 12.8034 5.86718 12.2013 6.1883 11.6594C6.34887 11.3784 6.60977 11.2981 6.89076 11.2981C7.19181 11.2981 7.49287 11.2981 7.85413 11.2981V11.3182Z"
                                        fill="white"
                                   ></path>
                                   <path
                                        d="M15.7214 10.5357C15.7214 9.9537 16.1429 9.5523 16.7049 9.53223C17.2668 9.53223 17.7084 9.97377 17.7084 10.5357C17.7084 11.1178 17.2869 11.5393 16.7049 11.5393C16.1228 11.5393 15.7214 11.1178 15.7214 10.5157V10.5357Z"
                                        fill="white"
                                   ></path>
                                   <path
                                        d="M13.6945 13.607C13.6945 13.025 14.1159 12.6035 14.698 12.6035C15.2599 12.6035 15.6814 13.025 15.6814 13.607C15.6814 14.1891 15.2599 14.6306 14.698 14.6306C14.1159 14.6306 13.6945 14.2091 13.6945 13.6271V13.607Z"
                                        fill="white"
                                   ></path>
                              </svg>
                         </template>
                    </Slider>
               </div>
               <div
                    class="w-full mb-5"
                    :class="similarGames?.data ? '' : 'mt-10'"
               >
                    <Slider
                         :data="hotGames"
                         title="Hot Games"
                         :overideOnlick="
                              (game_name, id) => goToDetail(game_name, id)
                         "
                    >
                         <template v-slot:icon>
                              <svg
                                   class="w-4"
                                   viewBox="0 0 20 26"
                                   fill="none"
                                   xmlns="http://www.w3.org/2000/svg"
                              >
                                   <path
                                        d="M8.12727 2.1654L8.89482 1.10334C9.07903 0.822201 9.38605 0.666016 9.72377 0.666016C10.3071 0.666016 10.7676 1.13457 10.7676 1.72808V2.82138C10.7676 3.47736 11.0439 4.1021 11.5045 4.53942L15.6185 8.53778C17.9825 10.8493 19.3334 14.0355 19.3334 17.3779C19.3334 22.1259 15.5571 25.9993 10.8597 25.9993H10.0001C4.84219 25.9993 0.666748 21.7511 0.666748 16.5033V16.3158C0.666748 13.9106 1.6185 11.599 3.2764 9.88098L3.46061 9.69355C3.67552 9.47489 3.95184 9.38118 4.22815 9.38118C4.84219 9.38118 5.33341 9.88098 5.33341 10.5057V14.9102C5.33341 16.6594 6.71499 18.0651 8.43429 18.0651C10.1536 18.0651 11.5352 16.6594 11.5352 14.9102V14.7227C11.5352 13.8481 11.1974 12.9735 10.5834 12.3487L8.71061 10.4432C7.54394 9.25624 6.8992 7.6319 6.8992 5.97633C6.8992 4.6019 7.32903 3.2587 8.12727 2.1654Z"
                                        fill="white"
                                   />
                              </svg>
                         </template>
                    </Slider>
               </div>
          </div>
          <loading-overlay :isLoading="loading" />
          <LaunchConfirmation
               :onLaunchGame="onLaunch"
               :gameDetails="activeGameDetails"
               :type="activeType"
          />
     </div>
</template>
<script>
import { Head as InertiaHead } from "@inertiajs/vue3";
import {
     gamesDetails as gamesDetailsAPI,
     games as gamesAPI,
     setFavorites as setFavoritesAPI
} from "@/api/games";
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useToaster } from "composables/use-toaster.js";
import LoadingOverlay from "components/loading/loadingOverlay.vue";
import { gamesStore } from "store/games";
import { computed } from "vue";
import _ from "underscore";
import { authStore } from "store/auth.js";
import { gameLauncher as gameLauncherAPI } from "@/api/seamless";
import { gamesSimilar as gamesSimilarAPI } from "@/api/games";
import eventBus from "plugins/event.js";
import { useScroll } from "composables/use-scroll.js";
import { baseUrl } from "config/services.js";
import LaunchConfirmation from "components/modals/launchConfirmation.vue";
import { useKYC } from "composables/use-kyc.js";
import { useActionRestriction } from "composables/use-restriction.js";
import Slider from "components/games/slider/index.vue";

export default {
     components: {
          InertiaHead,
          LoadingOverlay,
          LaunchConfirmation,
          Slider
     },
     setup() {
          const { open_kyc_game_launcher } = useKYC();
          const { isActionRestricted } = useActionRestriction();

          const route = useRoute();
          const router = useRouter();

          const loading = ref(false);

          const gamesDetails = ref(null);
          const hotGames = ref(null);
          const pageRandom = ref(1);

          const provider = ref(null);
          const similarGames = ref(null);

          const activeGameDetails = ref(null);
          const activeType = ref(null);

          const { scrollTop } = useScroll();

          const useGamesStore = gamesStore();
          const useAuthStore = authStore();

          const { errorToast } = useToaster();

          const providerData = computed(() => {
               return useGamesStore.provider || null;
          });

          const isUser = computed(() => {
               return useAuthStore.user;
          });

          const heartData = computed(() => {
               return useGamesStore.heart || null;
          });

          const getGame = async () => {
               await gamesDetailsAPI({
                    game_id: route.path.split("/")[3]
               })
                    .then((res) => {
                         gamesDetails.value = res;

                         provider.value = _.filter(
                              providerData.value,
                              function (params) {
                                   return params["id"] == res?.provider_id;
                              }
                         );
                    })
                    .catch(() => {
                         errorToast("Something went wrong!", "top-right");
                         router.push({ name: "NotFound" });
                    });
          };

          const getHotGames = async () => {
               await gamesAPI({
                    page: pageRandom.value,
                    itemsPerPage: 20,
                    game_type: [],
                    game_promo: 1,
                    game_name: "",
                    provider_id: []
               })
                    .then((res) => {
                         hotGames.value = res?.games;
                         pageRandom.value =
                              res?.games?.last_page == 1
                                   ? 1
                                   : Math.floor(
                                          Math.random() *
                                               (res?.games?.last_page - 2) +
                                               1
                                     );
                    })
                    .catch(() => {
                         errorToast("Something went wrong!", "top-right");
                    });
          };

          const goToDetail = async (name, id) => {
               loading.value = true;
               router.push({
                    path:
                         "/games/detail/" + name.replaceAll(" ", "-") + "-" + id
               });
               setTimeout(async () => {
                    scrollTop();
                    await getGame();
                    await getSimilarGames();
                    await getHotGames();
                    loading.value = false;
               }, 1000);
          };

          const onLaunch = async (slug, type) => {
               if (isUser.value) {
                    if (open_kyc_game_launcher() == true) {
                         return;
                    }

                    const filter = _.filter(
                         providerData.value,
                         function (params) {
                              return params.id == slug.provider_id;
                         }
                    );

                    await gameLauncherAPI(
                         { game_slug: slug.game_slug, type: type },
                         filter[0]["game_source"]
                    )
                         .then((res) => {
                              if (res.status) {
                                   if (
                                        /iPad|iPhone|iPod/.test(
                                             navigator.userAgent
                                        )
                                   ) {
                                        // alert("This is an iOS device.");
                                        setTimeout(() => {
                                             //  window.open(res.url, "_blank");
                                             closeLaunchConfirmation();
                                             useAuthStore.setActiveCoinType(
                                                  type
                                             );
                                             useAuthStore.setActiveGameUrl(
                                                  res.url
                                             );
                                             router.push({
                                                  name: "play",
                                                  params: {
                                                       slug: slug.game_slug,
                                                       id: slug.provider_id,
                                                       launchcode:
                                                            res.launch_code,
                                                       type: type
                                                  }
                                             });
                                        });
                                   } else {
                                        // window.open(res.url, "_blank");
                                        closeLaunchConfirmation();
                                        useAuthStore.setActiveCoinType(type);
                                        useAuthStore.setActiveGameUrl(res.url);
                                        router.push({
                                             name: "play",
                                             params: {
                                                  slug: slug.game_slug,
                                                  id: slug.provider_id,
                                                  launchcode: res.launch_code,
                                                  type: type
                                             }
                                        });
                                   }
                              }
                         })
                         .catch(() => {
                              closeLaunchConfirmation();
                              errorToast("Something went wrong!", "top-right");
                         });
               } else {
                    router.push({ name: "login" });
               }
          };

          const getSimilarGames = async () => {
               similarGames.value = null;
               const params = {
                    game_name: gamesDetails.value["game_name"],
                    provider_id: provider.value[0]["id"]
               };
               await gamesSimilarAPI(params)
                    .then((res) => {
                         similarGames.value = { data: res };
                         // console.log(similarGames.value);
                    })
                    .catch((e) => {
                         console.error(e);
                    });
          };

          const openLaunchConfirmation = (game_details, type) => {
               if (open_kyc_game_launcher() == true) {
                    return;
               }
               activeGameDetails.value = game_details;
               activeType.value = type;
               eventBus.emit("open:launchconfirmation");
          };

          const closeLaunchConfirmation = () => {
               eventBus.emit("close:launchconfirmation");
          };

          const checkActionRestricted = () => {
               // PLAY
               const restrictedActionType = 3;

               return isActionRestricted(restrictedActionType);
          };

          const onHeart = async (id, status) => {
               const params = { game_id: id };

               if (status == true) {
                    params["unset"] = 1;
               }

               await setFavoritesAPI(params);
               await useGamesStore.getHeart(true);
          };

          onMounted(async () => {
               scrollTop();
               await useGamesStore.getProvider();
               await useGamesStore.getHeart();
               await getGame();
               await getSimilarGames();
               await getHotGames();
          });

          return {
               gamesDetails,
               hotGames,
               goToDetail,
               loading,
               providerData,
               provider,
               isUser,
               onLaunch,
               similarGames,
               baseUrl,
               openLaunchConfirmation,
               activeGameDetails,
               activeType,
               checkActionRestricted,
               heartData,
               onHeart
          };
     }
};
</script>
